import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken, uInfo } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'react-loader';
import Modal from 'react-bootstrap/Modal'
import Slider from "react-slick";
import Picker from 'emoji-picker-react';
import $ from 'jquery';
import { RiThumbUpLine, RiThumbUpFill } from "react-icons/ri";
import Like from '../../assets/images/like1.png';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link } from "react-router-dom";
import { BsDot } from 'react-icons/bs'
import LoginBox from '../../components/other/account/LoginBox';
import bin from "../../assets/images/bin.png"
import { Dropdown } from "react-bootstrap";
import iconMore from '../../assets/images/icons_more-horizontal.png';
import { FaEdit } from 'react-icons/fa';
import SingleFileUpload from "../../components/common/SingleFileUpload"
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Comment1 from '../../assets/images/comment.png';

class ManagePost extends Component {
    constructor() {
        super();
        this.state = {
            search_data: {},
            items: [],
            chosenEmoji: {},
            commentBox: "",
            postID: 0,
            loader: false,
            editPostId: 0,
            editPost: {},
            file: null
        };
        this.onHide = this.onHide.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handler1 = this.handler1.bind(this);
        this.fileSet = this.fileSet.bind(this);
        this.child = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    fileSet = (files) => {
        if (files) {
            this.setState({
                file: files,
                photo: 'set'
            })
        } else {
            this.setState({
                file: null,
                photo: ''
            })
        }
    }

    handleSubmit(event, values) {
        if (this.state.editPostId == 0) {
            toast.error('No Post selected, Select Post');
            return;
        }
        this.setState({
            loader: false,
        });
        var formData = new FormData();
        formData.append("id", this.state.editPostId);
        formData.append("title", values.post_title);
        formData.append("description", values.message);
        formData.append("link", values.post_link == null ? '' : values.post_link);
        formData.append("link_text", values.link_text == null ? '' : values.link_text);
        formData.append("device", 'Web');
        formData.append("zip_code", '');
        if (this.state.file != '' && this.state.file != null) {
            this.state.file.map((item) => {
                formData.append('photo[]', item);
                formData.append('photo_type[]', item.type);
            });
        }

        var self = this;
        // if (errors.length < 1) {
        axios.post(ApiUrl + 'updateBusinessPost', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        file: null,
                        loader: true,
                        photo: '',
                        editPost: {},
                        editPostId: 0
                    })
                    self.getuserBusinessPost();
                } else {
                    toast.error(res.data.message);
                    self.setState({
                        file: null,
                        loader: true,
                        photo: ''
                    })
                }
            })
            .catch(function (error) {
                self.setState({
                    file: null,
                    loader: true
                })
                console.log(error);
            });
        // }
    }


    onHide() {
        this.setState({ LoginModal: false, GalleryModal: false });
    }
    handler1(e) {
        if (e) {
            this.setState({
                LoginModal: false,
            });

            this.props.handler2();
        }
        // this.props.history.go('/')
    }


    componentDidMount() {
        this.getuserBusinessPost();
        // comment_reply_
        // handleReplyText1
        // 
    }

    onEmojiReplyClick = (event, emojiObject, id) => {
        let stuff = this.state['comment_reply_' + id];
        let text = stuff + emojiObject.emoji;
        this.setState({
            ['comment_reply_' + id]: text
        });
    };


    handleReplyText1 = (e, id) => {
        let text = e.target.value;
        this.setState({
            [e.target.name]: text
        });
    }



    handleReplyKeyDown(e, id, index, total_comments, commentIndex, replyId) {
        var self = this;
        if (e.key === 'Enter') {
            e.preventDefault();
            let comment = self.state['comment_reply_' + replyId];
            self.setState({
                ['comment_reply_' + replyId]: ''
            });
            axios.post(ApiUrl + 'addPostComment', { 'comment': comment, 'post_id': id, reply_id: replyId }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {

                        let stateCopy = { ...self.state.items }
                        let oilSite = stateCopy[index]; //Get site by index

                        console.log(oilSite.comments[commentIndex])
                        stateCopy[index].comments[commentIndex] = res.data.comments;
                        //Add property to site
                        stateCopy[index] = oilSite;//update array
                        //Update the state
                        self.setState(stateCopy);





                        // let stateCopy = { ...self.state.items }
                        // let oilSite = stateCopy[index]; //Get site by index
                        // oilSite.total_comments = total_comments;
                        // oilSite.comments = [...oilSite.comments, res.data.comments]


                        // //Add property to site
                        // stateCopy[index] = oilSite;//update array
                        // //Update the state
                        // self.setState(stateCopy);
                    } else {
                        toast.error(res.data.message);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    handleKeyDown(e, id, index, total_comments, replyId) {
        var self = this;
        if (e.key === 'Enter') {
            e.preventDefault();
            let comment = self.state['comment_' + id];
            self.setState({
                ['comment_' + id]: ''
            });
            axios.post(ApiUrl + 'addPostComment', { 'comment': comment, 'post_id': id, reply_id: replyId }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        let data = {
                            full_name: uInfo().full_name,
                            image: uInfo().image,
                            comment: comment,
                            created_at: 'now',
                            replies: []
                        }

                        let stateCopy = { ...self.state.items }
                        let oilSite = stateCopy[index]; //Get site by index
                        oilSite.total_comments = total_comments;

                        oilSite.comments = [...oilSite.comments, res.data.comments]
                        //Add property to site
                        stateCopy[index] = oilSite;//update array
                        //Update the state
                        self.setState(stateCopy);
                    } else {
                        toast.error(res.data.message);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    actionLike = (id, activty, index, total_like) => {
        var self = this;
        axios.post(ApiUrl + 'addBusinessPostLike', { 'is_like': activty, 'post_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    let stateCopy = { ...self.state.items }
                    let oilSite = stateCopy[index]; //Get site by index
                    oilSite.is_like = activty;
                    oilSite.total_likes = total_like;
                    //Add property to site
                    stateCopy[index] = oilSite;//update array
                    //Update the state
                    self.setState(stateCopy);
                } else {
                    toast.error(res.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    actionCommentLike = (id, activty, index, total_like, commentIndex, parent_id) => {

        console.log(id, activty, index, total_like, commentIndex, parent_id, 'id, activty, index, total_like, commentIndex, parent_id')
        var self = this;
        axios.post(ApiUrl + 'addPostCommentReaction', { 'is_like': activty, 'comment_id': id, 'parent_id': parent_id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {

                    let stateCopy = { ...self.state.items }
                    let oilSite = stateCopy[index]; //Get site by index

                    console.log(oilSite.comments[commentIndex])
                    stateCopy[index].comments[commentIndex] = res.data.comments;
                    //Add property to site
                    stateCopy[index] = oilSite;//update array
                    //Update the state
                    self.setState(stateCopy);




                    // let stateCopy = { ...self.state.items }
                    // let oilSite = stateCopy[index].comments; //Get site by index
                    // oilSite[commentIndex] =  res.data.comments;

                    // oilSite.comments = oilSite;
                    // //Add property to site
                    // stateCopy[index] = oilSite;//update array
                    // //Update the state
                    // self.setState(stateCopy);

                    // let stateCopy = { ...self.state.items }
                    // let oilSite = stateCopy[index]; //Get site by index
                    // let oilCommentLike = oilSite.comments[commentIndex];
                    // oilCommentLike.is_like = activty;
                    // oilCommentLike.total_likes = total_like;
                    // stateCopy[index] = oilSite;//update array
                    // self.setState(stateCopy);
                } else {
                    toast.error(res.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }



    onEmojiClick = (event, emojiObject, id) => {
        let stuff = this.state['comment_' + id];

        console.log(stuff, 'ssss')
        let text = '';
        if (stuff != undefined) {
            text = stuff + emojiObject.emoji;
        } else {
            text = emojiObject.emoji;
        }

        this.setState({
            ['comment_' + id]: text
        });
    };


    handleText1 = (e, id) => {
        let text = e.target.value;
        this.setState({
            [e.target.name]: text
        });
    }

    getuserBusinessPost = () => {
        this.setState({ loader: false })
        var self = this;
        axios.post(ApiUrl + 'userBusinessPost', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        items: res.data.lists,
                        loader: true
                    })
                } else {
                    self.setState({
                        loader: true
                    })
                }
            })
            .catch(function (error) {
                self.setState({
                    loader: true
                })
                console.log(error);
            });
    }

    Delete = (id) => {
        var self = this;
        axios.post(ApiUrl + 'deleteBusinessPost', { 'post_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.getuserBusinessPost();
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <main className="dashboard-page">
                <Loader loaded={this.state.loader}></Loader>
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        <Col lg={9} md={9} id="page-content-wrapper">
                            <div className='dashboard-area '>
                                <section className="y-page-link manage-post">
                                    <h4 className='text-black pb-3'>Manage Post</h4>
                                    <div className="cities recent posts" style={{ paddingTop: "30px !important" }}>
                                        {this.state.items.map((item, i) => {
                                            return (
                                                <>
                                                    <div id={`post${item.id}`} key={i + '-' + item.business_name} className={`col-lg-9 ${this.state.editPostId == item.id ? 'd-none' : 'd-nlock'}`}>
                                                        <div className={`chat-meta-button d-block`}>

                                                            <Dropdown variant="none">
                                                                <Dropdown.Toggle variant="none" id={`dropdown-basic` + item.id}>
                                                                    <img src={iconMore} alt="user" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>

                                                                    <ul className="User-Chat-Button">
                                                                        <li onClick={() => this.setState({ editPostId: item.id, editPost: item })} ><FaEdit /> <button className="user-control">Edit </button> </li>
                                                                        <li onClick={() => this.Delete(item.id)} ><img src={bin} /> <button className="user-control">Delete </button> </li>
                                                                    </ul>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="card-item col-last first-col">
                                                            <div className="ReviewsWrote">
                                                                <div className="card-image slider-gallery">
                                                                    <Slider {...settings}>
                                                                        {
                                                                            item.images.map((image, ki) => {
                                                                                return (
                                                                                    <div key={ki} className="slide-slider">
                                                                                        <Link to={`/block/details/${item.id}`} >
                                                                                            {
                                                                                                image.type == 'video' ? (
                                                                                                    <video controls
                                                                                                        preload="auto" loop=""   >
                                                                                                        <source type="video/mp4"
                                                                                                            src={image.image}>
                                                                                                        </source>
                                                                                                    </video>
                                                                                                ) :
                                                                                                    <img src={image.image} />
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Slider>
                                                                </div>
                                                                <div className="media m-round last-col">
                                                                    <div className="media-left">
                                                                        <div className="User_Info">
                                                                            <Link to={{
                                                                                pathname: `/biz/${item.business_slug}`,
                                                                            }}>
                                                                                <img width="100%" height="100%" className="media-object" src={item.business_logo} alt="business_logo" />
                                                                            </Link>

                                                                            <div className="media-body m-body">
                                                                                <h4 className="media-heading m-h4">
                                                                                    <a>{item.business_name}</a>
                                                                                </h4>
                                                                                <p className="m-p">{item.created_at}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="post-desc">
                                                                        <h1>{item.title}</h1>
                                                                        <p className={`descs`}> {item.description} </p>
                                                                    </div>

                                                                </div>
                                                                <div className={`media-body item-heading b-heading ${item.post_type_color_code}`}>
                                                                    <div className="bus-name">
                                                                        <h4 className="media-heading m-h4">
                                                                            <a>{item.business_name}</a>
                                                                        </h4>
                                                                        <span className="post-zipcode-meta" >{item.post_type_title} <BsDot /> Located in {item.zipcode}</span>
                                                                    </div>
                                                                    {
                                                                        item.link != null && (
                                                                            <div className="learn-btn"><a href={item.link} target={'_blank'} className="learn">
                                                                                {
                                                                                    item.link_text != null ? item.link_text : "Learn More"
                                                                                }
                                                                            </a></div>
                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="user_review">
                                                                <div className={`like-commment`}>
                                                                    <span>{item.total_likes} Like</span>
                                                                </div>
                                                                <div onClick={() => this.setState({ SelectCommentBoxID: this.state.SelectCommentBoxID == item.id ? 0 : item.id })} className={`like-commment comment-open`}>
                                                                    <span>{item.total_comments} Comment</span>
                                                                </div>
                                                            </div>
                                                            {(!uToken()) ?
                                                                <div className="user_review post-reaction-count not-login">
                                                                    <Button variant="none" title="Like"
                                                                        onClick={() => this.setState({ LoginModal: true })}
                                                                        className={`btn btn-link card__img img-thumbnail default`}
                                                                    >
                                                                        <img width="100%" height="100%" src={Like} className="card__img img-thumbnail" alt="review" /><span>Like</span>
                                                                    </Button>

                                                                    <Button variant="none" title="Comment"
                                                                        onClick={() => this.setState({ LoginModal: true })}
                                                                        className={`btn btn-link card__img img-thumbnail default`}
                                                                    >
                                                                        <img width="100%" height="100%" src={Comment1} className="card__img img-thumbnail" alt="smile" /><span>Comment</span>
                                                                    </Button>
                                                                </div>
                                                                :
                                                                <div className='post-meta post-reaction-count'>
                                                                    <div className="user_review">

                                                                        {
                                                                            item.is_like == 0 ? (
                                                                                <Button variant="none" title="Like"
                                                                                    onClick={() => this.actionLike(item.id, 1, i, item.total_likes + 1)}
                                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                                >
                                                                                    <span><RiThumbUpLine /> Like</span>
                                                                                </Button>
                                                                            ) :
                                                                                (
                                                                                    <Button variant="none" title="Like"
                                                                                        onClick={() => this.actionLike(item.id, 0, i, item.total_likes - 1)}
                                                                                        className={`btn btn-link card__img img-thumbnail `}
                                                                                    >
                                                                                        <span><RiThumbUpFill /> Like </span>
                                                                                    </Button>
                                                                                )
                                                                        }

                                                                        <Button variant="none" title="Comment"
                                                                            onClick={() => { this.setState({ postID: item.id }); $('#comment_' + item.id).focus() }}
                                                                            className={`btn btn-link card__img img-thumbnail default`}
                                                                        >
                                                                            <img width="100%" height="100%" src={Comment1} className="card__img img-thumbnail" alt="smile" /><span>Comment</span>
                                                                        </Button>
                                                                    </div>


                                                                </div>

                                                            }

                                                            <div className='post-meta'>

                                                                {
                                                                    uToken() && (
                                                                        <div className="user_review">

                                                                            <div className='comment-post-area'>

                                                                                <div className="d-flex mt-3">
                                                                                    <div className="user-img-comment">
                                                                                        <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                    </div>

                                                                                    <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                        placeholder={`Write a comment…`}
                                                                                        name={`comment_${item.id}`}
                                                                                        value={this.state[`comment_${item.id}`]}
                                                                                        id={`comment_${item.id}`}
                                                                                        onChange={(e) => this.handleText1(e, item.id, i, item.total_comments + 1)}
                                                                                        onKeyDown={(e) => this.handleKeyDown(e, item.id, i, item.total_comments + 1, 0)}
                                                                                    ></textarea>

                                                                                    <div className='comment-emoji-area'>

                                                                                        <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: item.id })}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                        </div>
                                                                                    </div>





                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        this.state.showPicker && this.state.SelectPickerID == item.id && (

                                                                                            <Picker onEmojiClick={(event, object) => this.onEmojiClick(event, object, item.id)} />
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                                <div className='comments'>



                                                                    <div className={`comment_area ${this.state.SelectCommentBoxID == item.id ? 'block' : 'none'} `}>

                                                                        {item.comments.slice(0, 1).map((comment, c) => {

                                                                            return (
                                                                                <div key={c + 'a'} className="comment storyComment reply-coment">
                                                                                    <div className="coment-user">
                                                                                        <img className="avatar__img" alt="Comment"
                                                                                            src={comment.image} />
                                                                                        <div className="meta-data">
                                                                                            <span className="comment__author">{comment.full_name} <span className="time-com">{comment.created_at}</span></span>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="comment-body">
                                                                                        <div className="msg-body">

                                                                                            <div className="d-flex">
                                                                                                <p className="comment-content">{comment.comment}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="comment-time">
                                                                                            {/* {comment.created_at}  */}



                                                                                            <Button variant="none" title="Like"
                                                                                                onClick={() => console.log('hi')}
                                                                                                className={`btn btn-link card__img img-thumbnail default`}
                                                                                            >
                                                                                                <span> {comment.total_likes} Like</span>
                                                                                            </Button>




                                                                                            {
                                                                                                uToken() ? (
                                                                                                    <>
                                                                                                        <Button variant="none" title="Comment"
                                                                                                            onClick={() => this.setState({ postCommentReplyID: this.state.postCommentReplyID != comment.id ? comment.id : 0 })}
                                                                                                            className={`btn btn-link card__img img-thumbnail default`}
                                                                                                        >
                                                                                                            <span>Reply</span>
                                                                                                        </Button>
                                                                                                        {
                                                                                                            comment.is_like == 0 ? (
                                                                                                                <Button variant="none" title="Like"
                                                                                                                    onClick={() => this.actionCommentLike(comment.id, 1, i, comment.total_likes + 1, c, comment.id)}
                                                                                                                    className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                                >
                                                                                                                    <span> <RiThumbUpLine /></span>
                                                                                                                </Button>
                                                                                                            ) :
                                                                                                                (
                                                                                                                    <Button variant="none" title="Like"
                                                                                                                        onClick={() => this.actionCommentLike(comment.id, 0, i, comment.total_likes - 1, c, comment.id)}
                                                                                                                        className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                                    >
                                                                                                                        <span><RiThumbUpFill /></span>
                                                                                                                    </Button>
                                                                                                                )
                                                                                                        }
                                                                                                    </>

                                                                                                )
                                                                                                    :
                                                                                                    <>
                                                                                                        <Button variant="none" title="Comment"
                                                                                                            onClick={() => this.setState({ LoginModal: true })}
                                                                                                            className={`btn btn-link card__img img-thumbnail default`}
                                                                                                        >
                                                                                                            <span>Reply</span>
                                                                                                        </Button>

                                                                                                        <Button variant="none" title="Like"
                                                                                                            onClick={() => this.setState({ LoginModal: true })}
                                                                                                            className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                        >
                                                                                                            <span><RiThumbUpLine /></span>
                                                                                                        </Button>




                                                                                                    </>
                                                                                            }
                                                                                        </div>

                                                                                        <div className='post-meta reply'>
                                                                                            <div className="user_review">

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={`user_review comment-reply ${this.state.postCommentReplyID == comment.id ? 'block' : 'none'} `}>

                                                                                            <div className='comment-post-area'>

                                                                                                <div className="d-flex mt-3">
                                                                                                    <div className="user-img-comment">
                                                                                                        <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                                    </div>

                                                                                                    <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                                        placeholder="Write a reply..."
                                                                                                        name={`comment_reply_${comment.id}`}
                                                                                                        value={this.state[`comment_reply_${comment.id}`]}
                                                                                                        onChange={(e) => this.handleReplyText1(e, comment.id, i, item.total_comments + 1)}
                                                                                                        onKeyDown={(e) => this.handleReplyKeyDown(e, item.id, i, item.total_comments + 1, c, comment.id)}
                                                                                                    ></textarea>

                                                                                                    <div className='comment-emoji-area'>

                                                                                                        <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: comment.id })}>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        this.state.showPicker && this.state.SelectPickerID == comment.id && (
                                                                                                            <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, comment.id)} />
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="reple">
                                                                                        {comment.replies.map((reply, r) => {

                                                                                            return (
                                                                                                <div key={c + '-' + r} className="comment storyComment reply-coment">
                                                                                                    <div className="coment-user">
                                                                                                        <img className="avatar__img" alt="Comment"
                                                                                                            src={reply.image} />
                                                                                                        <div className="meta-data">
                                                                                                            <span className="comment__author">{reply.full_name} <span className="time-com">{reply.created_at}</span></span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="comment-body">
                                                                                                        <div className="msg-body">

                                                                                                            <div className="d-flex">
                                                                                                                <p className="comment-content">{reply.comment}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="comment-time">
                                                                                                            {/* {comment.created_at}  */}



                                                                                                            <Button variant="none" title="Like"
                                                                                                                onClick={() => console.log('hi')}
                                                                                                                className={`btn btn-link card__img img-thumbnail default`}
                                                                                                            >
                                                                                                                <span> {reply.total_likes} Like</span>
                                                                                                            </Button>



                                                                                                            {
                                                                                                                uToken() ? (
                                                                                                                    <>

                                                                                                                        {
                                                                                                                            reply.is_like == 0 ? (
                                                                                                                                <Button variant="none" title="Like"
                                                                                                                                    onClick={() => this.actionCommentLike(reply.id, 1, i, reply.total_likes + 1, c, comment.id)}
                                                                                                                                    className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                                                >
                                                                                                                                    <span> <RiThumbUpLine /></span>
                                                                                                                                </Button>
                                                                                                                            ) :
                                                                                                                                (
                                                                                                                                    <Button variant="none" title="Like"
                                                                                                                                        onClick={() => this.actionCommentLike(reply.id, 0, i, reply.total_likes - 1, c, comment.id)}
                                                                                                                                        className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                                                    >
                                                                                                                                        <span><RiThumbUpFill /></span>
                                                                                                                                    </Button>
                                                                                                                                )
                                                                                                                        }
                                                                                                                    </>

                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <Button variant="none" title="Like"
                                                                                                                            onClick={() => this.setState({ LoginModal: true })}
                                                                                                                            className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                                        >
                                                                                                                            <span> <RiThumbUpLine /></span>
                                                                                                                        </Button>
                                                                                                                    </>
                                                                                                                )
                                                                                                            }


                                                                                                        </div>


                                                                                                        <div className={`user_review comment-reply ${this.state.postCommentReplyID == reply.id ? 'block' : 'none'} `}>

                                                                                                            <div className='comment-post-area'>

                                                                                                                <div className="d-flex mt-3">
                                                                                                                    <div className="user-img-comment">
                                                                                                                        <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                                                    </div>

                                                                                                                    <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                                                        placeholder="Write a reply..."
                                                                                                                        name={`comment_reply_${reply.id}`}
                                                                                                                        value={this.state[`comment_reply_${reply.id}`]}
                                                                                                                        onChange={(e) => this.handleReplyText1(e, reply.id, i, reply.total_comments + 1)}
                                                                                                                        onKeyDown={(e) => this.handleReplyKeyDown(e, reply.id, i, reply.total_comments + 1, comment.id)}
                                                                                                                    ></textarea>

                                                                                                                    <div className='comment-emoji-area'>

                                                                                                                        <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: reply.id })}>
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {
                                                                                                                        this.state.showPicker && this.state.SelectPickerID == reply.id && (
                                                                                                                            <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, reply.id)} />
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                        {item.comments.length > 1 && (

                                                                            <Accordion className="commentReply accordion accordion-item" allowZeroExpanded={true} id="accordionExample1">

                                                                                <div className={'card'} key={i}>
                                                                                    <AccordionItem>

                                                                                        <AccordionItemPanel>
                                                                                            <div className="comment_area">
                                                                                                {item.comments.slice(1, item.comments.length).map((comment, c) => {
                                                                                                    return (
                                                                                                        <div key={c + 1} className="comment storyComment reply-coment">
                                                                                                            <div className="coment-user">
                                                                                                                <img className="avatar__img" alt="Comment"
                                                                                                                    src={comment.image} />
                                                                                                                <div className="meta-data">
                                                                                                                    <span className="comment__author">{comment.full_name} <span className="time-com">{comment.created_at}</span></span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="comment-body">
                                                                                                                <div className="msg-body">

                                                                                                                    <div className="d-flex">
                                                                                                                        <p className="comment-content">{comment.comment}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="comment-time">
                                                                                                                    {/* {comment.created_at}  */}



                                                                                                                    <Button variant="none" title="Like"
                                                                                                                        onClick={() => console.log('hi')}
                                                                                                                        className={`btn btn-link card__img img-thumbnail default`}
                                                                                                                    >
                                                                                                                        <span> {comment.total_likes} Like</span>
                                                                                                                    </Button>




                                                                                                                    {
                                                                                                                        uToken() ? (
                                                                                                                            <>
                                                                                                                                <Button variant="none" title="Comment"
                                                                                                                                    onClick={() => this.setState({ postCommentReplyID: this.state.postCommentReplyID != comment.id ? comment.id : 0 })}
                                                                                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                                                                                >
                                                                                                                                    <span>Reply</span>
                                                                                                                                </Button>
                                                                                                                                {
                                                                                                                                    comment.is_like == 0 ? (
                                                                                                                                        <Button variant="none" title="Like"
                                                                                                                                            onClick={() => this.actionCommentLike(comment.id, 1, i, comment.total_likes + 1, c + 1, comment.id)}
                                                                                                                                            className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                                                        >
                                                                                                                                            <span> <RiThumbUpLine /></span>
                                                                                                                                        </Button>
                                                                                                                                    ) :
                                                                                                                                        (
                                                                                                                                            <Button variant="none" title="Like"
                                                                                                                                                onClick={() => this.actionCommentLike(comment.id, 0, i, comment.total_likes - 1, c + 1, comment.id)}
                                                                                                                                                className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                                                            >
                                                                                                                                                <span><RiThumbUpFill /></span>
                                                                                                                                            </Button>
                                                                                                                                        )
                                                                                                                                }
                                                                                                                            </>

                                                                                                                        )
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                <Button variant="none" title="Comment"
                                                                                                                                    onClick={() => this.setState({ LoginModal: true })}
                                                                                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                                                                                >
                                                                                                                                    <span>Reply</span>
                                                                                                                                </Button>

                                                                                                                                <Button variant="none" title="Like"
                                                                                                                                    onClick={() => this.setState({ LoginModal: true })}
                                                                                                                                    className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                                                >
                                                                                                                                    <span><RiThumbUpLine /></span>
                                                                                                                                </Button>




                                                                                                                            </>
                                                                                                                    }
                                                                                                                </div>

                                                                                                                <div className='post-meta reply'>
                                                                                                                    <div className="user_review">

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className={`user_review comment-reply ${this.state.postCommentReplyID == comment.id ? 'block' : 'none'} `}>

                                                                                                                    <div className='comment-post-area'>

                                                                                                                        <div className="d-flex mt-3">
                                                                                                                            <div className="user-img-comment">
                                                                                                                                <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                                                            </div>

                                                                                                                            <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                                                                placeholder="Write a reply…"
                                                                                                                                name={`comment_reply_${comment.id}`}
                                                                                                                                value={this.state[`comment_reply_${comment.id}`]}
                                                                                                                                onChange={(e) => this.handleReplyText1(e, comment.id, i, item.total_comments + 1)}
                                                                                                                                onKeyDown={(e) => this.handleReplyKeyDown(e, item.id, i, item.total_comments + 1, c + 1, comment.id)}
                                                                                                                            ></textarea>

                                                                                                                            <div className='comment-emoji-area'>

                                                                                                                                <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: comment.id })}>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            {
                                                                                                                                this.state.showPicker && this.state.SelectPickerID == comment.id && (
                                                                                                                                    <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, comment.id)} />
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="reple">
                                                                                                                {comment.replies.map((reply, r) => {

                                                                                                                    return (
                                                                                                                        <div key={c + '-' + r} className="comment storyComment reply-coment">
                                                                                                                            <div className="coment-user">
                                                                                                                                <img className="avatar__img" alt="Comment"
                                                                                                                                    src={reply.image} />
                                                                                                                                <div className="meta-data">
                                                                                                                                    <span className="comment__author">{reply.full_name} <span className="time-com">{reply.created_at}</span></span>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="comment-body">
                                                                                                                                <div className="msg-body">

                                                                                                                                    <div className="d-flex">
                                                                                                                                        <p className="comment-content">{reply.comment}
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="comment-time">
                                                                                                                                    {/* {comment.created_at}  */}



                                                                                                                                    <Button variant="none" title="Like"
                                                                                                                                        onClick={() => console.log('hi')}
                                                                                                                                        className={`btn btn-link card__img img-thumbnail default`}
                                                                                                                                    >
                                                                                                                                        <span> {reply.total_likes} Like</span>
                                                                                                                                    </Button>



                                                                                                                                    {
                                                                                                                                        uToken() ? (
                                                                                                                                            <>

                                                                                                                                                {
                                                                                                                                                    reply.is_like == 0 ? (
                                                                                                                                                        <Button variant="none" title="Like"
                                                                                                                                                            onClick={() => this.actionCommentLike(reply.id, 1, i, reply.total_likes + 1, c + 1, comment.id)}
                                                                                                                                                            className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                                                                        >
                                                                                                                                                            <span> <RiThumbUpLine /></span>
                                                                                                                                                        </Button>
                                                                                                                                                    ) :
                                                                                                                                                        (
                                                                                                                                                            <Button variant="none" title="Like"
                                                                                                                                                                onClick={() => this.actionCommentLike(reply.id, 0, i, reply.total_likes - 1, c + 1, comment.id)}
                                                                                                                                                                className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                                                                            >
                                                                                                                                                                <span><RiThumbUpFill /></span>
                                                                                                                                                            </Button>
                                                                                                                                                        )
                                                                                                                                                }
                                                                                                                                            </>

                                                                                                                                        ) : (
                                                                                                                                            <>
                                                                                                                                                <Button variant="none" title="Like"
                                                                                                                                                    onClick={() => this.setState({ LoginModal: true })}
                                                                                                                                                    className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                                                                >
                                                                                                                                                    <span> <RiThumbUpLine /></span>
                                                                                                                                                </Button>
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    }


                                                                                                                                </div>


                                                                                                                                <div className={`user_review comment-reply ${this.state.postCommentReplyID == reply.id ? 'block' : 'none'} `}>

                                                                                                                                    <div className='comment-post-area'>

                                                                                                                                        <div className="d-flex mt-3">
                                                                                                                                            <div className="user-img-comment">
                                                                                                                                                <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                                                                            </div>

                                                                                                                                            <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                                                                                placeholder="Write a reply…"
                                                                                                                                                name={`comment_reply_${reply.id}`}
                                                                                                                                                value={this.state[`comment_reply_${reply.id}`]}
                                                                                                                                                onChange={(e) => this.handleReplyText1(e, reply.id, i, reply.total_comments + 1)}
                                                                                                                                                onKeyDown={(e) => this.handleReplyKeyDown(e, reply.id, i, reply.total_comments + 1, comment.id)}
                                                                                                                                            ></textarea>

                                                                                                                                            <div className='comment-emoji-area'>

                                                                                                                                                <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: reply.id })}>
                                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            {
                                                                                                                                                this.state.showPicker && this.state.SelectPickerID == reply.id && (
                                                                                                                                                    <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, reply.id)} />
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        </AccordionItemPanel>
                                                                                        <AccordionItemHeading className="card-header post-view-header">
                                                                                            <AccordionItemButton className="reply-comment-shown  post-view btn btn-link d-flex align-items-center">

                                                                                                <i className="plus post-view-button">View more comments</i>
                                                                                                <i className="minus post-view-button">View less comments</i>
                                                                                            </AccordionItemButton>
                                                                                        </AccordionItemHeading>
                                                                                    </AccordionItem>
                                                                                </div>
                                                                            </Accordion>
                                                                        )}





                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-lg-9 ${this.state.editPostId == item.id ? 'd-block' : 'none'}`}>
                                                        <AvForm encType="multipart/form-data" ref={c => (this.form = c)} onValidSubmit={this.handleSubmit}>


                                                            <AvGroup>
                                                                <label>Post Title</label>
                                                                <AvInput
                                                                    placeholder=""
                                                                    className="form-control"
                                                                    name="post_title"
                                                                    required
                                                                    value={this.state.editPost.title}
                                                                />
                                                                <AvFeedback>This field is required</AvFeedback>
                                                            </AvGroup>

                                                            <AvGroup>
                                                                <label>Enter your message</label>
                                                                <AvInput
                                                                    type="textarea"
                                                                    placeholder=""
                                                                    className="form-control"
                                                                    name="message"
                                                                    rows={6}
                                                                    maxLength={3000}
                                                                    required
                                                                    value={this.state.editPost.description}
                                                                />
                                                                <AvFeedback>This field is required</AvFeedback>
                                                            </AvGroup>

                                                            <AvGroup>
                                                                <label>Add a link (optional)</label>
                                                                <AvInput
                                                                    type="url"
                                                                    placeholder=""
                                                                    className="form-control"
                                                                    name="post_link"
                                                                    value={this.state.editPost.link}
                                                                />
                                                                <AvFeedback> Enter a valid url *</AvFeedback>
                                                            </AvGroup>


                                                            <AvGroup>
                                                                <label>Select button (optional)</label>
                                                                <AvInput
                                                                    type="select"
                                                                    placeholder=""
                                                                    className="form-control"
                                                                    name="link_text"
                                                                    value={this.state.editPost.link_text}
                                                                >
                                                                    <option value={''}>Select Button</option>
                                                                    <option value="Learn More">Learn More</option>
                                                                    <option value="RSVP Now">RSVP Now</option>
                                                                    <option value="Shop Now">Shop Now</option>
                                                                    <option value="Buy Now">Buy Now</option>
                                                                    <option value="Watch Video">Watch Video</option>
                                                                    <option value="Contact Us">Contact Us</option>
                                                                    <option value="Sign Up">Sign Up</option>
                                                                </AvInput>
                                                                <AvFeedback>This field is required</AvFeedback>
                                                            </AvGroup>



                                                            <AvGroup>
                                                                <label>Add photos or videos <p> Recommended dimensions: (500px500px)</p></label>
                                                                {this.state.loader && (<SingleFileUpload fileSet={this.fileSet} ref="child" />)}
                                                                <AvInput type='hidden' name="selectfile" value={this.state.photo} />
                                                                <AvFeedback>This field is required</AvFeedback>
                                                            </AvGroup>

                                                            <div className="post-action save-button">
                                                                <Button type="submit" className="save-changes" variant="primary">Update</Button>
                                                                <Button onClick={() => this.setState({ editPost: {}, editPostId: 0 })} type="reset" className="save-changes cancel" variant="none">Cancel</Button>
                                                            </div>

                                                            <div className="padding-bottom-80px"></div>
                                                        </AvForm>
                                                    </div>
                                                </>
                                            )
                                        })}



                                        <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>  </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {/* <h5>Login required to perform this action </h5> */}
                                                <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                                            </Modal.Body>
                                        </Modal>


                                        <Modal show={this.state.openModal} onHide={this.onHide} size="lg"
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title> Make an Appointment </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <iframe src={this.state.calandlyUrl} frameBorder="0" width="100%" height="350px" />
                                            </Modal.Body>
                                        </Modal>

                                        <ToastContainer />
                                    </div>
                                </section>
                            </div>
                            <ToastContainer />
                        </Col>
                    </Row>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default ManagePost;