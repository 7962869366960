import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import '../../assets/css/style2.css';
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { BiCheck, BiEdit } from 'react-icons/bi';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Button from 'react-bootstrap/Button'
import SingleFileUpload from "../../components/common/SingleFileUpload"
import Loader from 'react-loader';
import Modal from 'react-bootstrap/Modal';

class Website extends Component {

    constructor(props) {
        super(props)
        this.state = {
            // lists: [
            //     {
            //         desc: 'The Most Delicious Restaurant',
            //         text: 'Business Name'

            //     },
            //     {
            //         desc: 'Food & Drink',
            //         text: 'Primary Category'
            //     },
            //     {
            //         desc: 'Restaurants',
            //         text: 'Specific Service'
            //     },
            //     {
            //         desc: 'Jamie Terry',
            //         text: 'Owner Name'
            //     }
            //     ,
            //     {
            //         desc: 'jterry@tmdrestaurant.com',
            //         text: 'Email'
            //     },
            //     {
            //         desc: '215-456-3954',
            //         text: 'Phone Number'
            //     },
            //     {
            //         web: 'www.tmdrestaurant.com',
            //         text: 'Website'
            //     }
            // ],
            // items: [
            //     {
            //         title: 'When is my Plan ready to use?',
            //         desc: 'Your upgraded plan will be ready to use immediately once you complete the payment process.',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            //     {
            //         title: 'Can I upgrade to a higher Plan later?',
            //         desc: 'Yes. All businesses start off with a Free account and you can upgrade at anytime.',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            //     {
            //         title: 'How long is the contract term?',
            //         desc: 'You are billed month to month. There are no long term contracts on Katika. You can cancel at anytime.',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            //     {
            //         title: 'How do I cancel my subscription?',
            //         desc: 'Simply log into your account and go to the Billing section of your account and select "Cancel Plan".',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            //     {
            //         title: 'Do you offer any other Plans?',
            //         desc: 'We are currently only offering these plans at this time to provide the best value for businesses.',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            // ],
            // monthly: [],
            // annually: [
            //     {
            //         id: 1,
            //         PackageType: 'BASIC',
            //         PackageName: 'Personal busines',
            //         PackagePrice: 'Free',
            //         CurrentPlan: 'Buy',
            //         rules: ['Create a free business page', 'Real time page analytics', 'Basic messaging function', 'Photo gallery with API']
            //     },
            //     {
            //         id: 4,
            //         PackageType: 'BUSINESS',
            //         PackageName: 'Small business',
            //         CurrentPlan: 'Buy',
            //         PackagePrice: '$14.99 /year',
            //         rules: ['Create targeted campaigns by zipcode', 'Share updates and announcements', 'Use rich media images and videos', 'Trusted secure environment']
            //     },
            //     {
            //         id: 5,
            //         PackageType: 'PRO',
            //         PackageName: 'Medium business',
            //         CurrentPlan: 'Buy',
            //         PackagePrice: '$24.99 /year',
            //         rules: ['Create e-brand loyalty club', 'Curate local customer list', 'Use rich media images and videos', 'In-App messaging capabilities']
            //     }
            // ],
            monthly: [],
            isWebsiteCreated: false,
            isSubscribed: false,
            defaultActiveTab: 'second',
            websitePlanUrl: '#',
            PackageType: 'BASIC',
            PackageName: 'Personal busines',
            PackagePrice: 'Free',
            CurrentPlan: 0,
            Terms: '*Free, forever. No credit cards required.',
            reason: '',
            fromDashboard: this.props.to ? true : false,
            domainFormData: {
                draftImage: 'https://katika-images.s3.amazonaws.com/category/Artboard%201default_biz1629443022.png',
                website_url: '',
                website_create: '',
                domain_name: '',
                website_edit_content: '',
                website_name: '',
                website_logo: 'https://katika-images.s3.amazonaws.com/category/Artboard%201default_biz1629443022.png',
                
            },

            template1: {
                id:  1,
                name:  'Template 1',
                slug: 'template1',
                color: 'red',
                image: 'https://katika.us/public/front/images/template-1.png',
                url: 'https://preview1.katika.us/',
            },

            template2: {
                id:  2,
                name:  'Template 2',
                slug: 'template2',
                color: 'black',
                image: 'https://katika.us/public/front/images/template-2.png',
                url: 'https://preview2.katika.us/',
            },

            template3: {
                id:  3,
                name:  'Template 3',
                slug: 'template3',
                color: 'black',
                image: 'https://katika.us/public/front/images/template-3.png',
                url: 'https://preview3.katika.us/',
            },

            // template4: {
            //     id:  4,
            //     name:  'Template 4',
            //     slug: 'template4',
            //     color: 'black',
            //     image: 'https://katika.us/public/front/images/template-4.png',
            //     url: 'https://preview4.katika.us/',
            // },

        }
        // this.handleChange = this.handleChange.bind(this);
    }

    handleTabSelect = (key) => {
        this.setState({ defaultActiveTab: key });
    };


    componentDidMount() {
        this.getWebsite();
        this.getPlan();

    }

    getWebsite = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        var self = this;
        axios.post(ApiUrl + 'getWebsite', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
        .then(function (res) {
            let setDefaultActiveTab = 'second';
            if(res.data.isSubscribed){
                setDefaultActiveTab = 'first';
            }
            self.setState({
                defaultActiveTab: setDefaultActiveTab,
                domainFormData: res.data.userDomain,
                template1: res.data.template1,
                template2: res.data.template2,
                template3: res.data.template3,
                // template4: res.data.template4,

                isWebsiteCreated: res.data.isWebsiteCreated,
                isSubscribed: res.data.isSubscribed,
                websitePlanUrl: res.data.websitePlanUrl,
                HandleWebsiteBuilderPlansPopup: false,
                // annually: res.data.annualyPlan,
                // externalData: true,
                // CurrentPlan: res.data.subscription
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    getPlan = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        var self = this;
        axios.post(ApiUrl + 'getWebsiteDreamerPlans', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
        .then(function (res) {
            self.setState({
                monthly: res.data.monthlyPlan,
                externalData: true,
                CurrentPlan: res.data.subscription
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    updateDomainStatus = (requestStatus) => {
        var self = this;
        self.setState({
            externalData: false,
        });
        axios.post(ApiUrl + 'updateDomainStatus', {status: requestStatus}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
        .then(function (res) {
            if (res.data.success === true) {
                self.setState(prevState => ({
                    domainFormData: {
                        ...prevState.domainFormData,
                        status: requestStatus
                    }
                }));
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

   
    handleActiveTab = (key) => {
        this.setState({defaultActiveTab: key});
    }

    updateDomainTemplate = (templateName) => {
        var self = this;
        self.setState({
            externalData: false,
        });
        axios.post(ApiUrl + 'updateDomainTemplate', {'template': templateName}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
        .then(function (res) {
            if (res.data.success === true) {
                self.getWebsite();
                // self.setState(prevState => ({
                //     domainFormData: {
                //         ...prevState.domainFormData,
                //         status: 'Active'
                //     }
                // }));
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }


    // handleChange() {
    //     let reason = this.state.reason;
    //     if (reason != '') {
    //         console.log(reason)
    //         axios.post(ApiUrl + 'deactiveBusiness ', { 'deactivate_reason': reason }, {
    //             headers: {
    //                 'Authorization': `Bearer ${uToken()}`
    //             }
    //         })
    //             .then(function (res) {
    //                 if (res.data.success === true) {
    //                     toast.success(res.data.message);
    //                 } else {
    //                     toast.error(res.data.message);
    //                 }
    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //             });
    //     } else {
    //         toast.error('Please select reason');
    //     }
    // }



    render() {
        return (
            <main className="dashboard-page account-page faqs-section">
                {/* Header */}
                {
                    !this.state.fromDashboard && (
                        <DashboardNavbar />
                    )
                }
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                
                <Container>
                    
                    <Row>
                        
                        {
                            !this.state.fromDashboard && (
                                <Col lg={3} md={3} id="sidebar-wrapper">
                                    <Sidebar />
                                </Col>
                            )
                        }
                        


                        <Col lg={this.state.fromDashboard ? 12 : 9} md={this.state.fromDashboard ? 12 : 9} id="page-content-wrapper">
                            <Tab.Container id="left-tabs-example" activeKey={this.state.defaultActiveTab}>

                                <Nav variant="pills" className="flex-column package_plan-tab">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" onClick={() => this.handleActiveTab('first') }>Katika Website Dreamer</Nav.Link>
                                        <Nav.Link eventKey="second" onClick={() => this.handleActiveTab('second') }>Plans & Pricing</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                                


                                <Tab.Content className='package_plan'>
                                    <Tab.Pane eventKey="first">
                                        {
                                            this.state.isSubscribed ? (
                                                <>
                                                    {
                                                        this.state.isWebsiteCreated ? (
                                                            <Row>
                                                                <Col lg={8} md={8} className='col-12'>
                                                                    <section className="dashboard-area mx-0 business-detail">
                                                                        <div className="y-page-link" style={{ backgroundColor: 'aliceblue' }}>

                                                                            <h2>Website</h2>
                                                                            
                                                                            <Row style={{ display: 'flex', alignItems: 'center' }}>                                                
                                                                                <Col lg={8} md={8} className='col-12'>
                                                                                    <section className="dashboard-area mx-0 business-detail">
                                                                                        <div>
                                                                                            <div>
                                                                                                <div style={{ display: 'flex', justifyContent: 'between', backgroundColor: '#bcbcbc', alignItems: 'center', padding: '5px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', borderTopRightRadius: '5px'}}>
                                                                                                    <ul style={{ display: 'contents', listColor: 'black'}}>
                                                                                                        <li style={{ backgroundColor: 'red', height: '10px', width: '10px', maxWidth: '10px', marginRight: '5px', borderRadius: '50%'}}></li>
                                                                                                        <li style={{ backgroundColor: 'white', height: '10px', width: '10px', maxWidth: '10px', marginRight: '5px', borderRadius: '50%'}}></li>
                                                                                                        <li style={{ backgroundColor: 'white', height: '10px', width: '10px', maxWidth: '10px', marginRight: '5px', borderRadius: '50%'}}></li>
                                                                                                    </ul>
                                                                                                    <p style={{ backgroundColor: 'white', color: 'black', marginLeft: '10px', borderRadius: '5px', padding: '5px 5px'}}>{this.state.domainFormData.website_url}</p>
                                                                                                </div>
                                                                                                
                                                                                                <iframe src={this.state.domainFormData.website_url} style={{ maxHeight: 'unset', maxWidth: '100%', minWidth: '100%', minHeight: '265px', overflow: 'auto', border: '1px solid #bcbcbc', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" scrolling="no" allowFullScreen></iframe>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    </section>
                                                                                </Col>
                                                                                <Col lg={4} md={4} className='col-12'>
                                                                                    <div>
                                                                                        <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>
                                                                                            <a href={this.state.domainFormData.website_url} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '5px 10px' }}>Preview Site</div></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                        </div>
                                                                    </section>
                                                                </Col>
                                                                <Col lg={4} md={4} className='col-12'>
                                                                    <section className="dashboard-area mx-0 business-detail">
                                                                        <div className="y-page-link">
                                                                            {
                                                                                this.state.domainFormData.status == 'Draft' ? (
                                                                                    <h2>DRAFT</h2>
                                                                                ) : (
                                                                                    <h2>Live</h2>
                                                                                )
                                                                            }
                                                                            <div className="UserPicture businessLogo">
                                                                                <img src={this.state.domainFormData.website_logo} alt="author" style={{ maxHeight: 'unset', maxWidth: '100%' }} />
                                                                            </div>
                                                                            <div>
                                                                                <p><b>{this.state.domainFormData.website_name}</b></p>
                                                                                <p>{this.state.domainFormData.domain_name}</p>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px', marginBottom: '10px' }}>
                                                                                    <a href={this.state.domainFormData.website_edit_content} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '5px 10px' }}>Edit Website</div></a>
                                                                                </div>
                                                                                {
                                                                                    this.state.domainFormData.status == 'Draft' ? (
                                                                                        <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>
                                                                                            <Link onClick={() => this.updateDomainStatus('Active')}> <div className="bg-FF6435 " style={{ color: 'black', padding: '5px 10px' }}>Publish Website</div></Link>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>
                                                                                            <Link onClick={() => this.updateDomainStatus('Draft')}> <div className="bg-FF6435 " style={{ color: 'black', padding: '5px 10px' }}>Unpublish</div></Link>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            <Row style={{ display: 'flex', alignItems: 'center' }}>                                                
                                                                <Col lg={12} md={12} className='col-12'>
                                                                    <div>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>
                                                                            <a href={this.state.domainFormData.website_create}>
                                                                                <div className="bg-FF6435 " style={{ color: 'black', padding: '5px 10px' }}>Create Website</div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row> 
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <Row style={{ display: 'flex', alignItems: 'center' }}>                                                
                                                    <Col lg={12} md={12} className='col-12'>
                                                        <div>
                                                            <div onClick={() => this.handleActiveTab('second') } style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px', cursor: 'pointer' }}>
                                                                <div className="bg-FF6435 " style={{ color: 'black', padding: '5px 10px' }}>Buy Now</div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row> 
                                            )
                                        }

                                        <Row className='mt-3'>
                                            <Col lg={4} md={4} className='col-12'>
                                                <section className="dashboard-area mx-0 business-detail">
                                                    <h5 style={{ color: 'black' }}>Templates</h5>
                                                </section>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} md={4} className='col-12'>
                                                <section className="dashboard-area mx-0 business-detail">
                                                    <div className="y-page-link">
                                                        <div className="UserPicture businessLogo">
                                                            <img src={this.state.template1.image} alt="author" style={{ maxHeight: 'unset', maxWidth: '100%' }} />
                                                        </div>
                                                        <div>
                                                            <p style={{ marginBottom: '20px', fontSize: 'large' }}>{this.state.template1.name}</p>
                                                            {
                                                                this.state.isWebsiteCreated ? (
                                                                    <Row>
                                                                        <Col lg={6} md={6} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'between' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid ' + this.state.template1.color, borderRadius: '5px', marginBottom: '10px' }}>
                                                                                    <Link onClick={() => this.updateDomainTemplate(this.state.template1.slug)}> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px'  }}>Activate</div></Link>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6} md={6} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'between' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>                                                            
                                                                                    <a href={this.state.template1.url} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Preview</div></a>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                ) : (
                                                                    <Row>
                                                                        <Col lg={12} md={12} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>                                                            
                                                                                <a href={this.state.template1.url} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Preview</div></a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            </Col>

                                            <Col lg={4} md={4} className='col-12'>
                                                <section className="dashboard-area mx-0 business-detail">
                                                    <div className="y-page-link">
                                                        <div className="UserPicture businessLogo">
                                                            <img src={this.state.template2.image} alt="author" style={{ maxHeight: 'unset', maxWidth: '100%' }} />
                                                        </div>
                                                        <div>
                                                            <p style={{ marginBottom: '20px', fontSize: 'large' }}>{this.state.template2.name}</p>
                                                            {
                                                                this.state.isWebsiteCreated ? (
                                                                    <Row>
                                                                        <Col lg={6} md={6} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'between' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid ' + this.state.template2.color, borderRadius: '5px', marginBottom: '10px' }}>
                                                                                    <Link onClick={() => this.updateDomainTemplate(this.state.template2.slug)}> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Activate</div></Link>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6} md={6} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'between' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>                                                            
                                                                                    <a href={this.state.template2.url} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Preview</div></a>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                ) : (
                                                                    <Row>
                                                                        <Col lg={12} md={12} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>                                                            
                                                                                <a href={this.state.template2.url} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Preview</div></a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            </Col>

                                            <Col lg={4} md={4} className='col-12'>
                                                <section className="dashboard-area mx-0 business-detail">
                                                    <div className="y-page-link">
                                                        <div className="UserPicture businessLogo">
                                                            <img src={this.state.template3.image} alt="author" style={{ maxHeight: 'unset', maxWidth: '100%' }} />
                                                        </div>
                                                        <div>
                                                            <p style={{ marginBottom: '20px', fontSize: 'large' }}>{this.state.template3.name}</p>
                                                            {
                                                                this.state.isWebsiteCreated ? (
                                                                    <Row>
                                                                        <Col lg={6} md={6} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'between' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid ' + this.state.template3.color, borderRadius: '5px', marginBottom: '10px' }}>
                                                                                    <Link onClick={() => this.updateDomainTemplate(this.state.template3.slug)}> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Activate</div></Link>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6} md={6} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'between' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>                                                            
                                                                                    <a href={this.state.template3.url} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Preview</div></a>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                ) : (
                                                                    <Row>
                                                                        <Col lg={12} md={12} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>                                                            
                                                                                <a href={this.state.template3.url} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Preview</div></a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            </Col>
                                            
                                        </Row>
                                        {/* <Row>
                                            <Col lg={4} md={4} className='col-12'>
                                                <section className="dashboard-area mx-0 business-detail">
                                                    <div className="y-page-link">
                                                        <div className="UserPicture businessLogo">
                                                            <img src={this.state.template4.image} alt="author" style={{ maxHeight: 'unset', maxWidth: '100%' }} />
                                                        </div>
                                                        <div>
                                                            <p style={{ marginBottom: '20px', fontSize: 'large' }}>{this.state.template4.name}</p>
                                                            {
                                                                this.state.isWebsiteCreated ? (
                                                                    <Row>
                                                                        <Col lg={6} md={6} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'between' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid ' + this.state.template4.color, borderRadius: '5px', marginBottom: '10px' }}>
                                                                                    <Link onClick={() => this.updateDomainTemplate(this.state.template4.slug)}> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px'  }}>Activate</div></Link>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6} md={6} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'between' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>                                                            
                                                                                    <a href={this.state.template4.url} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Preview</div></a>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                ) : (
                                                                    <Row>
                                                                        <Col lg={12} md={12} className='col-12'>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid black', borderRadius: '5px' }}>                                                            
                                                                                <a href={this.state.template4.url} target='_blank'> <div className="bg-FF6435 " style={{ color: 'black', padding: '2px 10px' }}>Preview</div></a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            </Col>
                                            
                                        </Row> */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <section className="account-page">
                                            <div className="y-page-link">
                                                <div className='mb-5 text-center'>
                                                    <h1 className='color-black mb-2'>Website Dreamer Pricing</h1>
                                                    <h4 className='color-black mb-2'>Providing affordable quality websites</h4>
                                                    <h6 className='color-black'>Billed monthly with the ability to cancel at anytime.</h6>
                                                </div>
                                            </div>
                                        </section>
                                        <Row>
                                            {this.state.monthly.map((monthly, i) => {
                                                let activeTemplate = monthly.status == 'Active' ? "white" : "lightgray";
                                                console.log('activeTemplate', activeTemplate);
                                                return (
                                                    
                                                    // <Col lg={4} md={4} className='col-12' key={i}>
                                                        <section className="dashboard-area account-page col-md-4 m-0" key={i}>
                                                            <div className="y-page-link" style={{backgroundColor: activeTemplate}}>
                                                                <div className='mb-5'>
                                                                    <h5 className='mb-3'> {  monthly.sub_title  }</h5>
                                                                    <h6>{monthly.description}</h6>
                                                                    {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                    <div className="current-pkg">
                                                                        <div className="pkg-box">
                                                                            {this.state.CurrentPlan == monthly.id ?
                                                                                'Your Current Plan'
                                                                                :
                                                                                monthly.status == 'Active' ?
                                                                                    <Link to={`website-dreamer-checkout/${monthly.slug}`}> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                                    :
                                                                                    'Coming soon'
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul className="PackageDetails">
                                                                    <h5 className='mb-2'>Website</h5>
                                                                    {
                                                                        monthly.rules_website && monthly.rules_website.map((website, k) => {
                                                                            return (
                                                                                <li key={k}>
                                                                                    <span
                                                                                        className={`check ${i !== 0 ? 'paid' : ''}`}
                                                                                        style={{ marginRight: '8px' }}
                                                                                        dangerouslySetInnerHTML={{ __html: website.icon.svg }}
                                                                                    />
                                                                                    <span>{website.icon.label}</span>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                                <ul className="PackageDetails">
                                                                    <h5 className='mb-2'>Marketing</h5>
                                                                    {
                                                                        monthly.rules_marketing && monthly.rules_marketing.map((marketing, k) => {
                                                                            return (
                                                                                <li key={k}>
                                                                                    <span
                                                                                        className={`check ${i !== 0 ? 'paid' : ''}`}
                                                                                        style={{ marginRight: '8px' }}
                                                                                        dangerouslySetInnerHTML={{ __html: marketing.icon.svg }}
                                                                                    />
                                                                                    <span>{marketing.icon.label}</span>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </section>
                                                    // </Col>
                                                )
                                            })}
                                        </Row>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Tab.Container>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                <Nav variant="pills" className="flex-column">
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="first">Billed Monthly</Nav.Link>
                                        <Nav.Link eventKey="second">Billed Annually</Nav.Link>
                                    </Nav.Item> */}

                                </Nav>

                                <Tab.Content className='package_plan'>
                                    <Tab.Pane eventKey="first">
                                        <Row>
                                            {/* {this.state.monthly.map((monthly, i) => {
                                                return (
                                                    <Col lg={4} md={4} className='col-12' key={i}>
                                                        <section className="dashboard-area mx-0 account-page">
                                                            <div className="y-page-link">
                                                                <div className='border-bootom'>
                                                                    <h6>{monthly.description}</h6>
                                                                    <h5> {  monthly.sub_title  }</h5>
                                                                    {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                    <div className="current-pkg">
                                                                        <div className="pkg-box">
                                                                            {this.state.CurrentPlan == monthly.id ?
                                                                                'Your Current Plan'
                                                                                :
                                                                                i == 0 ?
                                                                                    <Link to={`#`} className='disabled'> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                                    :
                                                                                    <Link to={`checkout/${monthly.slug}`}> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul className="PackageDetails">
                                                                    {
                                                                        monthly.rules && monthly.rules.map((rule, k) => {
                                                                            return (
                                                                                <li key={k}><span className={`check ${i != 0 ? 'paid' : ''}`}><BiCheck /></span>{rule}</li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                                {i == 0 ? <div className="terms">{this.state.Terms}</div> : ""}
                                                            </div>
                                                        </section>
                                                    </Col>
                                                )
                                            })} */}
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Row>
                                            {/* {this.state.annually.map((monthly, i) => {
                                                return (
                                                    <Col lg={4} md={4} className='col-12' key={i}>
                                                        <section className="dashboard-area mx-0 account-page">
                                                            <div className="y-page-link">
                                                                <div className='border-bootom'>
                                                                    <h6>{monthly.description}</h6>
                                                                    <h5> {  monthly.sub_title  }
                                                                    </h5>
                                                                    {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                    <div className="current-pkg">
                                                                        <div className="pkg-box">
                                                                            {this.state.CurrentPlan == monthly.id ?
                                                                                'Your Current Plan'
                                                                                :
                                                                                i == 0 ?
                                                                                    <Link to={`#`} className='disabled'> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                                    :
                                                                                    <Link to={`checkout/${monthly.slug}`}> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul className="PackageDetails">

                                                                    {
                                                                        monthly.rules && monthly.rules.map((rule, k) => {
                                                                            return (
                                                                                <li key={k}><span className={`check ${i != 0 ? 'paid' : ''}`}><BiCheck /></span>{rule}</li>
                                                                            );
                                                                        })

                                                                    }
                                                                </ul>
                                                                {i == 0 ? <div className="terms">{this.state.Terms}</div> : ""}
                                                            </div>
                                                        </section>
                                                    </Col>
                                                )
                                            })} */}
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>


                            </Tab.Container>
                            {/* {
                                !this.state.fromDashboard && (
                                    <>
                                       
                                        <div className="FaqsQuestion pt-3 ">
                                            <div className="SubHeading text-align-center padding-bottom-30px">Get in touch</div>
                                            <section className="faq-area">
                                                <Accordion className="accordion accordion-item" id="accordionExample" allowZeroExpanded={true}>

                                                    {this.state.items.map((item, i) => {
                                                        return (
                                                            <div className={'card ' + item.cardClass} key={i}>
                                                                <AccordionItem>
                                                                    <AccordionItemHeading className="card-header">
                                                                        <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                            {item.title}
                                                                            <i className="minus">{item.minus}</i>
                                                                            <i className="plus">{item.plus}</i>
                                                                        </AccordionItemButton>
                                                                    </AccordionItemHeading>
                                                                    <AccordionItemPanel>
                                                                        <div className="card-body">
                                                                            {item.desc}
                                                                        </div>
                                                                    </AccordionItemPanel>
                                                                </AccordionItem>
                                                            </div>
                                                        )
                                                    })}

                                                </Accordion>
                                            </section>
                                        </div>
                                    </>
                                )
                            } */}
                        </Col>

                    </Row>
                    
                    <ToastContainer />
                </Container>
                {
                    !this.state.fromDashboard && (
                        <div className="margin-top-50px">
                            {/* Footer */}
                            <Footer />
                        </div>
                    )
                }
            </main >




        );
    }
}

export default Website;