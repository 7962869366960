import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import GeneralMap from "../../components/contact/GeneralMap";
import WidgetOpenHours from "../../components/sidebars/widgets/WidgetOpenHoursDashboard";
import { Container, Row, Col } from "react-bootstrap";
import Copy from "../../assets/images/link-copy.png";
import Open from "../../assets/images/open-icon.png";
import Edit from "../../assets/images/bx_bx-edit.png";

import Complete from "../../assets/images/complete.png";
import Incomplete from "../../assets/images/incomplete.png";
import Weavaillble from "../../assets/images/Weavaillble.png";
import Starfilled from '../../assets/images/starfilled.png';


import { Link } from 'react-router-dom';
import { ApiUrl, AutoApiKey, buyerUrl, adminUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Select from 'react-select'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import GeneralSearchMap from './GeneralSearchMap';
import Autocomplete from 'react-google-autocomplete';
import { RiDeleteBinLine, RiAddLine } from 'react-icons/ri'
import { Collapse, CardBody, Card } from 'reactstrap';
import Autosuggest from 'react-autosuggest';
import getAdderss from 'extract-country-state-city-from-zip';
import $ from 'jquery'
import Inputmask from "inputmask";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { ShareButtonRectangle, ShareBlockStandard, ShareButton } from 'react-custom-share';
import ShareFb from "../../assets/images/share-fb.svg";
import { Fa500Px, FaInfo, FaStar } from 'react-icons/fa';

import Account from "./Account";


const getSuggestionValue = (suggestion) => suggestion.city
const renderSuggestion = (suggestion) => <span>{suggestion.city}</span>
const popover = (title, body) => (
    <Popover id={`popover-positioned`}>
        <Popover.Title as="h3">{title}
        </Popover.Title>
        <Popover.Content>
            {body}
        </Popover.Content>
    </Popover>
);


class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            token: this.props.setToken,
            externalData: false,
            workingHours: "",
            keywords: "",
            editSocialMediaModal: false,
            prevPath: this.props ? this.props.location ? this.props.location.state ? this.props.location.state.prevPath : "" : "" : "",
            socialMedia: {

                fb_url: 'facebook.com/thebestshop',
                insta_url: 'Facebook',
                linkedin_url: 'LinkedId',
                twitter_url: 'Twitter'

            },
            categories: [
                {
                    id: 'The Most Delicious Restaurant',
                    name: 'Business Name'

                },
                {
                    id: 'Food & Drink',
                    name: 'Primary Category'
                },
                {
                    id: 'Restaurants',
                    name: 'Specific Service'
                }
            ],
            subcategories: [
                {
                    id: 'The Most Delicious Restaurant',
                    name: 'Business Name'

                },
                {
                    id: 'Food & Drink',
                    name: 'Primary Category'
                },
                {
                    id: 'Restaurants',
                    name: 'Specific Service'
                }
            ],
            filters: [],
            lists: [
                {
                    desc: 'The Most Delicious Restaurant',
                    text: 'Business Name'

                },
                {
                    desc: 'Food & Drink',
                    text: 'Primary Category'
                },
                {
                    desc: 'Restaurants',
                    text: 'Specific Service'
                },
                {
                    desc: 'Jamie Terry',
                    text: 'Owner Name'
                }
                ,
                {
                    desc: 'jterry@tmdrestaurant.com',
                    text: 'Email'
                },
                {
                    desc: '215-456-3954',
                    text: 'Phone Number'
                },
                {
                    web: 'www.tmdrestaurant.com',
                    text: 'Website'
                }
            ],
            business: '',
            business_loc: '',
            isChecked: false,
            mapTitle: 'Location & Hours',
            contentstate: {
                direction: '1304 Walnut St Philadelphia, PA 19107',
                CertificateTitle: 'Certificate From MBDSCE',
                CertificateDesc: 'MBE Certified 2019',
                CertificateTitleTwo: 'Certificate From Restaurant Association',
                CertificateDescTwo: 'SERVSAFE Certified 2018',

            },
            message: "https://www.shopkatika.com/directory/themostdeliciousrestaurant",
            btnText: "Copy",
            openModal: false,
            WarningAlert: false,
            editUrlModal: false,
            editProfileModal: false,
            editDescriptionModal: false,
            editKeywordModal: false,
            bFilter: [],
            bSelectFilter: [],
            tagsKeyword: [],
            tagsOwnerName: [],
            editWorkingHourModal: false,
            editAddressWorkingModal: false,
            editAddressModal: false,
            businessdirection: {},
            addCertificationModal: false,
            businessCertification: [],
            businessAddresses: [],
            isOpen: false,
            neighborhoods: [],
            selectedNeighborhood: [],
            BAmount: false,
            cityList: [],
            value: '',
            suggestions: [],
            onlineBusiness: false,
            complete_section: [],
            AccountPayModal: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.change = this.change.bind(this);
        this.onHide = this.onHide.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOwner = this.handleChangeOwner.bind(this);
        this.changeMainCategory = this.changeMainCategory.bind(this);
        this.changeSubCategory = this.changeSubCategory.bind(this);
        this.handler = this.handler.bind(this);
        this.changeNeighborhood = this.changeNeighborhood.bind(this)
    }


    onlineBusiness = (e) => {
        if (e.target.checked === true) {
            this.setState({
                onlineBusiness: true,
            })
        } else {
            this.setState({
                onlineBusiness: false
            })
        }
    }

    handler = (place) => {
        // console.log('hi')
        console.log(place)
        let city = '';
        let region = '';
        let country = '';
        let postalcode = '';
        let place1 = place;
        place1.address_components.map(pl => {
            if (pl.types[0] == 'locality' && pl.types[1] == 'political') {
                city = pl.short_name;
            }
            if (pl.types[0] == 'administrative_area_level_1' && pl.types[1] == 'political') {
                region = pl.short_name;
            }

            if (pl.types[0] == 'country' && pl.types[1] == 'political') {
                country = pl.short_name;
            }

            if (pl.types[0] == 'postal_code') {
                postalcode = pl.short_name;
            }
        })

        let formatted_address = place.formatted_address;
        let latitude = place.geometry.location.lat();
        let longitude = place.geometry.location.lng();
        this.setState({
            businessdirection: {
                business_location: formatted_address,
                business_lat: latitude,
                business_long: longitude,
                business_state: region,
                business_city: city,
                business_country: country,
                business_zip_code: postalcode
            }
        });


        // this.Neighborhoods();

    }
    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        if (this.state.businessdirection != prevState.businessdirection) {
            // console.log(prevState.businessdirection, 'prevState')
            this.Neighborhoods();
        }
    }


    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue, search: newValue,
            businessdirection: { business_city: newValue, business_state: '' }
        });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        // const client = algoliasearch(algoliaAppId, algoliaAppKeyId);
        // const index = client.initIndex('katika_restaurant');
        // // Search for a first name
        // index.search(value).then(({ hits }) => {
        //     this.setState({ suggestions: hits })
        // });
        fetch(`${ApiUrl}getCities?city=${value}`)
            .then(response => response.json())
            .then(data => this.setState({ suggestions: data.lists }))
    }

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };


    Neighborhoods = () => {
        var self = this;
        self.setState({
            neighborhoods: []
        })
        axios.post(ApiUrl + 'getCityNeighborhoods', {
            business_city: this.state.businessdirection.business_city,
            business_state: this.state.businessdirection.business_state
        }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    self.setState({
                        neighborhoods: res.data.data,
                        selectedNeighborhood: [],
                    })
                } else {
                    self.setState({
                        neighborhoods: [],
                        selectedNeighborhood: []
                    })
                }

            })
            .catch(function (error) {
                self.setState({
                    neighborhoods: [],
                })
                console.log(error);
            });
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    // handle copy to clipboard
    copyToClipboard = e => {
        let text = document.getElementById('holdtext');
        text.style.display = 'block';
        text.select();
        // copy the text in the textarea
        try {
            let status = document.execCommand("Copy");
            if (!status) {
                console.log('Cannot copy text');
            } else {
                // toastr.success('Copied');
                this.setState({ btnText: 'Copied!' });
            }
        } catch (err) {
            // toastr.error('Could not copy');
            // handle error
        }
        text.style.display = 'none';
    }

    componentDidMount() {

        let token = JSON.parse(localStorage.getItem('token'));
        if (this.state.prevPath == 'login' && token.login_first == 0) {
            // this.setState({
            //     AccountPayModal: true,
            // })
            token.login_first = 1;
            localStorage.setItem('token', JSON.stringify(token))
        }


        this.getBusinessDetail();
        Inputmask({ "mask": "(999) 999-9999" }).mask('.phone_re');

    }

    ZipCode = (e) => {
        let zip = '';
        if (e.target.value) {
            zip = e.target.value
        }
        let self = this;
        if (e.target.value.length >= 5) {
            axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + zip + '&sensor=true&key=' + AutoApiKey).then((response) => {
                console.log(response, 'ssas')
                if (response.data.results[0].address_components && response.status != 'OK') {
                    let addressArray = response.data.results[0].address_components;
                    let city = '';
                    let state = '';
                    let country = '';
                    let address = response.data.results[0].formatted_address;
                    let lat = response.data.results[0].geometry.location.lat;
                    let lng = response.data.results[0].geometry.location.lng;

                    for (let i = 0; i < addressArray.length; i++) {
                        if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0] && 'political' === addressArray[i].types[1]) {
                            state = addressArray[i].short_name;
                        }

                        if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0] && 'political' === addressArray[i].types[1]) {
                            city = addressArray[i].long_name;
                        }
                        if (addressArray[i].types[0] && 'country' === addressArray[i].types[0] && 'political' === addressArray[i].types[1]) {
                            country = addressArray[i].short_name;

                        }




                        // lat: this.state.business_loc.business_lat, lng: this.state.business_loc.business_long,
                        // address: this.state.business_loc.business_address
                    };
                    self.setState({
                        value: city + ' (' + state + ')',
                        businessdirection: {
                            business_city: city,
                            business_state: state,
                            business_lat: lat,
                            business_long: lng,
                            business_location: address,
                            business_country: country,
                            business_zip_code: zip
                        },
                        business_loc: { lat: lat, lng: lng, address: address }
                    })




                } else {
                    self.setState({
                        value: '',
                    })
                }
            }).catch((error) => {
                self.setState({
                    value: '',
                })
                console.log(error);
            });
            self.Neighborhoods();

        }

        // getAdderss(zip, AutoApiKey, (err, res) => err ?
        //     this.setState({
        //         value: '',
        //         businessdirection: ''
        //     }) :
        //     res.city ?  : "")





    }
    getBusinessDetail = () => {
        var self = this;

        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'userBusiness', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                let filter = [];
                let keyTag = [];
                let OwnerName = [];
                if (res.data.data.business_filters) {
                    filter = res.data.data.business_filters.split(',')
                }

                if (res.data.data.keywords) {
                    keyTag = res.data.data.keywords.split(',')
                }

                if (res.data.data.owner_name) {
                    OwnerName = res.data.data.owner_name.split(',')
                }

                if (res.data.data.business_category_id == 1) {
                    self.setState({
                        BAmount: true
                    })
                }

                if (res.data.data.online_business == 'No') {
                    self.setState({
                        onlineBusiness: true,
                        value: res.data.data.business_city + '(' + res.data.data.business_state + ')',
                    })
                }


                console.log(res.data.data.complete_percent, 'res.data.complete_section')

                let token = JSON.parse(localStorage.getItem('token'));
                if (res.data.data.complete_percent <= 70 && self.state.prevPath == 'login' && token.path_login == 1) {
                    self.setState({
                        WarningAlert: true,
                    })
                    token.path_login = 0;
                    localStorage.setItem('token', JSON.stringify(token))
                }


                self.setState({
                    business: res.data.data,
                    business_loc: res.data.data,
                    cityList: res.data.city,
                    workingHours: res.data.businessWorkingHours,
                    editBusinessWorkingHours: res.data.editBusinessWorkingHours,
                    keywords: res.data.data.keywords,

                    socialMedia: {
                        fb_url: res.data.data.fb_url,
                        insta_url: res.data.data.insta_url,
                        twitter_url: res.data.data.twitter_url,
                        linkedin_url: res.data.data.linkedin_url
                    },
                    tagsOwnerName: OwnerName,
                    tagsKeyword: keyTag,
                    // externalData: true,
                    categories: res.data.categories,
                    subcategories: res.data.subcategories,
                    filters: res.data.filters,
                    // images: res.data.images,
                    // reviews: res.data.reviews,
                    externalData: true,
                    bSelectFilter: res.data.data.get_business_filters,
                    bFilter: filter,
                    businessdirection: {
                        business_location: res.data.data.business_address,
                        business_lat: res.data.data.business_lat,
                        business_long: res.data.data.business_long,
                        business_state: res.data.data.business_state,
                        business_city: res.data.data.business_city,
                        business_country: '',
                        business_zip_code: ''
                    },
                    // selectedNeighborhood: res.data.data.selected_neighborhoods.value,
                    seletNeighborh: res.data.data.selected_neighborhoods,
                    businessCertification: res.data.businessCertification,
                    businessAddresses: res.data.businessAddresses,
                    complete_section: res.data.complete_section,

                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleChange(tagsKeyword) {
        console.log('tags', tagsKeyword)
        this.setState({ tagsKeyword })
    }


    handleChangeOwner(tagsOwnerName) {
        this.setState({ tagsOwnerName })
    }



    onHide() {
        this.setState({ openModal: false, WarningAlert: false, AccountPayModal: false });
    }

    changeMainCategory(e) {
        var self = this;

        if (e == 1) {
            self.setState({
                BAmount: true
            })
        } else {
            self.setState({
                BAmount: false
            })
        }
        axios.post(ApiUrl + 'getSubCategories', {
            'category_id': e
        }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    subcategories: res.data.lists,
                    filters: res.data.filters,
                    bFilter: [],
                    bSelectFilter: [],
                })

                self.setState(prevState => ({
                    business: {                   // object that we want to update
                        ...prevState.business,    // keep all other key-value pairs
                        business_sub_category_id: ''       // update the value of specific key
                    }
                }))

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    changeSubCategory(e) {
        var self = this;
        axios.post(ApiUrl + 'getSubCategoriesFilters', {
            'category_id': e
        }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    // subcategories: res.data.lists,
                    filters: res.data.filters,
                    bFilter: [],
                    bSelectFilter: [],
                })

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    changeNeighborhood(e) {
        // var neighborhood = [];
        // console.log(e, 'ne')
        // if (e) {
        //     e.map(fil => {
        //         neighborhood.push(fil.value);
        //     })
        // }
        this.setState({ seletNeighborh: e, selectedNeighborhood: e.value })
    }

    change(e) {
        var filter = [];
        if (e) {
            e.map(fil => {
                filter.push(fil.value);
            })
        }
        this.setState({ bFilter: filter, bSelectFilter: e })

    }


    removeOtherLocation(id) {
        var self = this;
        const newList = this.state.businessAddresses.filter((item) => item.id !== id);
        axios.post(ApiUrl + 'deleteBusinessLocations', { 'id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        businessAddresses: newList
                    });
                    // self.getBusinessDetail();

                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    removeCertification(id) {
        var self = this;
        const newList = this.state.businessCertification.filter((item) => item.id !== id);
        axios.post(ApiUrl + 'deleteBusinessCertifications', { 'id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        businessCertification: newList
                    });
                    // self.getBusinessDetail();

                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    handleSubmit(event, errors, values) {
        this.setState({ errors, values });
        var self = this;
        if (errors.length < 1) {
            let url = '';
            values.business_id = this.state.business.business_id;
            if (this.state.editUrlModal) {
                url = ''
            }

            if (this.state.editProfileModal) {
                url = 'updateBusinessProfileSummary';
                // values.category_filters = this.state.bFilter
            }

            if (this.state.editVideoModal) {
                url = 'updateBusinessVideo';
            }

            if (this.state.editDescriptionModal) {
                url = 'updateBusinessDescription';
            }

            if (this.state.editKeywordModal) {
                url = 'updateBusinessKeywords';
                values.keywords = this.state.tagsKeyword.toString();
            }

            if (this.state.editWorkingHourModal) {
                url = 'updateBusinessHours';
            }

            if (this.state.editSocialMediaModal) {
                url = 'updateBusinessSocialMedia';
            }

            if (this.state.editAddressWorkingModal) {
                url = 'updateBusinessAddressAndHours';
                let direction = this.state.businessdirection
                values.direction = direction
                values.business_neighborhoods = this.state.selectedNeighborhood.toString();
                // console.log(values)
            }

            // if (this.state.editAddressModal) {
            //     if (this.state.businessdirection) {
            //         url = 'updateBusinessAddress';
            //         values = this.state.businessdirection;
            //         values.business_id = this.state.business.business_id;
            //     }

            // }

            if (this.state.addCertificationModal) {
                url = 'addBusinessCertifications';
            }

            if (this.state.AddLocationModal) {
                url = 'addBusinessOtherLocations';
            }

            axios.post(ApiUrl + url, values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({
                            openModal: false,
                            editProfileModal: false,
                            editDescriptionModal: false,
                            editKeywordModal: false,
                            editAddressWorkingModal: false,
                            addCertificationModal: false,
                            onlineBusiness: false,
                            editVideoModal: false,
                            editSocialMediaModal: false,
                            AddLocationModal: false,
                        });
                        self.getBusinessDetail();

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }

    //////////////////////////////////////////////////////////////////

    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0] && 'political' === addressArray[i].types[1]) {
                city = addressArray[i].short_name;
                return city;
            }
        }
    };
    /**
      * Get the area and set the area input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getArea = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };
    /**
      * Get the address and set the address input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };


    getZipCode = (addressArray) => {
        let zipcode = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
                    zipcode = addressArray[i].short_name;
                    return zipcode;
                }
            }
        }
    };


    getLat = (addressArray) => {
        return addressArray.lat();
    };

    getLng = (addressArray) => {
        return addressArray.lng();
    };

    onLocationChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onPlaceSelected = (place) => {
        console.log(place, 'place')
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = this.getCity(addressArray),
            zipcode = this.getZipCode(addressArray),
            business_lat = this.getLat(place.geometry.location),
            business_long = this.getLng(place.geometry.location),
            state = this.getState(addressArray);

        // Set these values in the state.
        this.setState({
            address: (address) ? address : '',
            zipcode: (zipcode) ? zipcode : '',
            city: (city) ? city : '',
            business_lat: (business_lat) ? business_lat : '',
            business_long: (business_long) ? business_long : '',
            state: (state) ? state : '',
        })
    };


    ////////////////////////////////////////////////////////////////////////

    render() {
        Inputmask({ "mask": "(999) 999-9999" }).mask('.phone_re');


        const url = `${buyerUrl}/p/${this.state.business.business_slug}`;

        const shareButtonPropsFb = {
            url: url,
            network: "Facebook",
            text: "",
            longtext: ""
        };


        const { value, suggestions } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Select City',
            value,
            type: "text",
            className: "form-control",
            onChange: this.onChange
        };
        let hours = []
        for (let i = 0; i < 24; ++i) {
            hours.push(i)
        }

        let days = []
        for (let j = 1; j <= 7; ++j) {
            if (j == 1) {
                days.push({
                    'key': j,
                    'day': 'Mon'
                })
            }
            if (j == 2) {
                days.push({
                    'key': j,
                    'day': 'Tue'
                })
            }

            if (j == 3) {
                days.push({
                    'key': j,
                    'day': 'Wed'
                })
            }

            if (j == 4) {
                days.push({
                    'key': j,
                    'day': 'Thu'
                })
            }

            if (j == 5) {
                days.push({
                    'key': j,
                    'day': 'Fri'
                })
            }

            if (j == 6) {
                days.push({
                    'key': j,
                    'day': 'Sat'
                })
            }
            if (j == 7) {
                days.push({
                    'key': j,
                    'day': 'Sun'
                })
            }

        }

        const places = [
            {
                name: "Newcastle",
                title: "Newcastle",
                lat: -33.0029954,
                lng: 147.204751,
                id: 1
            }
        ];
        return (
            <main className="dashboard-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            {(this.state.externalData) ? <Sidebar percent={this.state.business.complete_percent} /> : ""}
                        </Col>
                        <Col lg={9} md={9} id="page-content-wrapper">
                            <section className="dashboard-area">
                                <div className="y-page-link">
                                    <h2>Your Katika business page link Copy</h2>
                                    {/* <div className="edit-content">
                                        <button onClick={() => console.log('')} className="remove-btn-bg" >
                                            <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                        </button>
                                    </div> */}

                                    {(this.state.editUrlModal) ?
                                        <Collapse isOpen={this.state.editUrlModal}>
                                            <Card className="card-custom-1">
                                                <CardBody >
                                                    <AvForm onSubmit={this.handleSubmit}>
                                                        <AvGroup>
                                                            {/* <AvInput
                                                            placeholder="Enter business url"
                                                            className="form-control"
                                                            name="website"
                                                            value={this.state.business.website}
                                                            required /> */}
                                                            <AvFeedback>Required Field *</AvFeedback>
                                                        </AvGroup>
                                                        <div className="button-modal-footer">
                                                            <Button variant="secondary" onClick={() => this.setState({ editUrlModal: !this.state.editUrlModal })}>Close</Button>
                                                            <Button type="submit" variant="primary" >Save Changes</Button>
                                                        </div>
                                                    </AvForm>
                                                </CardBody>
                                            </Card>
                                        </Collapse> :
                                        <>
                                            <textarea
                                                id="holdtext"
                                                style={{ display: "none" }}
                                                ref="textArea"
                                                value={`${buyerUrl}/biz/${this.state.business.business_slug}`}
                                                onChange={e => this.setState({ message: e.target.value, btnText: "Copy to Clipboard" })}
                                            />

                                            <div className="b-fb-share button-profile">
                                                <ShareButton className="remove-btn-bg font-size-12px" {...shareButtonPropsFb}>
                                                    <span className="dashboard-icon">Share to Facebook </span>
                                                </ShareButton>

                                            </div>

                                            <div className="b-fb-share button-profile button-open">

                                                <a href={`${buyerUrl}/biz/${this.state.business.business_slug}`} className="remove-btn-bg font-size-12px" target="_blank">
                                                    <span className="dashboard-icon">Open</span>
                                                </a>
                                            </div>
                                            <div className="b-fb-share button-profile button-copy">
                                                <button onClick={this.copyToClipboard} className="remove-btn-bg font-size-12px" >
                                                    <span className="dashboard-icon">{this.state.btnText}</span>
                                                </button>
                                            </div>
                                            <br />
                                            <div className="d-url">
                                                <a target="_blank" className="web-link" href={`${buyerUrl}/biz/${this.state.business.business_slug}`} > {`${buyerUrl}/biz/${this.state.business.business_slug}`}</a>
                                            </div>



                                        </>
                                    }
                                </div>

                                <Modal show={this.state.openModal} onHide={this.onHide} >
                                    <Modal.Header>
                                        <Modal.Title>
                                            {(this.state.editUrlModal) ?
                                                'Business update'
                                                : (this.state.editProfileModal) ?
                                                    'Business profile summary update'
                                                    : (this.state.editDescriptionModal) ?

                                                        'Business description update'
                                                        : (this.state.editKeywordModal) ?
                                                            'Business keywords update'
                                                            : (this.state.editWorkingHourModal) ?
                                                                'Business working hour update'
                                                                : (this.state.editAddressModal) ?
                                                                    'Business location update'
                                                                    : (this.state.addCertificationModal) ?
                                                                        'Business Certification'
                                                                        :
                                                                        ""
                                            }
                                        </Modal.Title>
                                     </Modal.Header>
                                    <AvForm onSubmit={this.handleSubmit}>
                                        <Modal.Body>
                                            {(this.state.editUrlModal) ?
                                                <AvGroup>
                                                    <AvInput
                                                        placeholder="Enter business url"
                                                        className="form-control"
                                                        name="website"
                                                        value={this.state.business.website}
                                                        required />
                                                    <AvFeedback>Required Field *</AvFeedback>
                                                </AvGroup>
                                                : (this.state.editProfileModal) ?
                                                    <>

                                                        <AvGroup>
                                                            <AvInput
                                                                placeholder="Enter business name"
                                                                className="form-control"
                                                                name="business_name"
                                                                value={this.state.business.business_name}
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup>

                                                        <AvField type="select"
                                                            name="business_category_id"
                                                            label=""
                                                            onChange={(e) => this.changeMainCategory(e.target.value)}
                                                            value={this.state.business.business_category_id}
                                                        >
                                                            {
                                                                this.state.categories.map(cat => {
                                                                    return (
                                                                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                                                                    )
                                                                })
                                                            }

                                                        </AvField>



                                                        <AvGroup>
                                                            <AvField type="select"
                                                                name="business_sub_category_id"
                                                                label=""
                                                                onChange={(e) => this.changeSubCategory(e.target.value)}
                                                                value={this.state.business.business_sub_category_id}
                                                                placeholder='Select subcategory'
                                                            >
                                                                {
                                                                    this.state.subcategories.map(subcat => {
                                                                        return (
                                                                            <option key={subcat.id} value={subcat.id}>{subcat.name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </AvField>
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup>
                                                        <Select placeholder='Select features'
                                                            options={this.state.filters}
                                                            defaultValue={this.state.bSelectFilter}
                                                            isMulti
                                                            value={this.state.bSelectFilter}
                                                            name="colors"
                                                            className="basic-multi-select mt-3 mb-3"
                                                            classNamePrefix="select"
                                                            onChange={this.change}
                                                        />


                                                        {/* <AvGroup>
                                                            <AvInput
                                                                placeholder="Select subcategory"
                                                                className="form-control"
                                                                name="sub_category"
                                                                value={this.state.business.sub_category}
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup> */}

                                                        <AvGroup>
                                                            <AvInput
                                                                placeholder="Enter owner name"
                                                                className="form-control"
                                                                name="owner_fname"
                                                                value={this.state.business.owner_name}
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup>

                                                        <AvGroup>
                                                            <AvInput
                                                                placeholder="Enter business email"
                                                                className="form-control"
                                                                name="business_email"
                                                                value={this.state.business.business_email}
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup>

                                                        <AvGroup>
                                                            <AvInput
                                                                placeholder="Enter phone number"
                                                                className="form-control"
                                                                name="phone_number"
                                                                value={this.state.business.phone_number}
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup>

                                                        <AvGroup>
                                                            <AvInput
                                                                placeholder="Enter website url"
                                                                className="form-control"
                                                                name="website"
                                                                value={this.state.business.website}
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup>
                                                    </>
                                                    : (this.state.editDescriptionModal) ?
                                                        <AvGroup>
                                                            <AvInput
                                                                placeholder="Enter business description"
                                                                className="form-control"
                                                                type="textarea"
                                                                rows="8"
                                                                name="business_description"
                                                                value={this.state.business.business_description}
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup>
                                                        : (this.state.editKeywordModal) ?
                                                            <TagsInput inputProps={{ placeholder: 'Enter Keyword', className: 'react-tagsinput-input' }} value={this.state.tagsKeyword} onChange={this.handleChange} />
                                                            : (this.state.editWorkingHourModal) ?
                                                                <>
                                                                    {
                                                                        days.map((d, j) => {
                                                                            return (
                                                                                <>
                                                                                    <div key={j} className="row">
                                                                                        <div className="col-md-4">
                                                                                            <AvGroup>
                                                                                                <AvInput
                                                                                                    placeholder="Enter business day"
                                                                                                    className="form-control"
                                                                                                    name={'day_' + d.key}
                                                                                                    value={d.day}
                                                                                                    readOnly
                                                                                                    required />
                                                                                                <AvFeedback> Required Field *</AvFeedback>
                                                                                            </AvGroup>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <AvField type="select"
                                                                                                name={'start_time_' + d.key}
                                                                                                label=""
                                                                                                value={(this.state.workingHours[j]) ? this.state.workingHours[j].start_time : "Closed"}
                                                                                            >
                                                                                                <option value="Closed">Closed</option>
                                                                                                {
                                                                                                    hours.map(hour => {
                                                                                                        var parsedDate = moment(`${hour}:00`, "H m");
                                                                                                        var parsedDate1 = moment(`${hour}:30`, "H m");
                                                                                                        return (
                                                                                                            <>
                                                                                                                <option key={parsedDate.format("hh:mm A")} value={parsedDate.format("hh:mm A")}>{parsedDate.format("hh:mm A")}</option>
                                                                                                                <option key={parsedDate1.format("hh:mm A")} value={parsedDate1.format("hh:mm A")}>{parsedDate1.format("hh:mm A")}</option>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }


                                                                                            </AvField>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <AvField type="select"
                                                                                                name={'end_time_' + d.key}
                                                                                                label=""
                                                                                                value={(this.state.workingHours[j]) ? this.state.workingHours[j].end_time : "Closed"}
                                                                                            >
                                                                                                <option value="Closed">Closed</option>
                                                                                                {
                                                                                                    hours.map(hour => {
                                                                                                        var parsedDate = moment(`${hour}:00`, "H m");
                                                                                                        var parsedDate1 = moment(`${hour}:30`, "H m");
                                                                                                        return (
                                                                                                            <>
                                                                                                                <option key={parsedDate.format("hh:mm A")} value={parsedDate.format("hh:mm A")}>{parsedDate.format("hh:mm A")}</option>
                                                                                                                <option key={parsedDate1.format("hh:mm A")} value={parsedDate1.format("hh:mm A")}>{parsedDate1.format("hh:mm A")}</option>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }


                                                                                            </AvField>
                                                                                        </div>
                                                                                    </div>

                                                                                </>
                                                                            );
                                                                        })}
                                                                </>
                                                                : (this.state.editAddressModal) ?
                                                                    <>
                                                                        <GeneralSearchMap onSelectLanguage={this.handler} />
                                                                    </>
                                                                    : (this.state.addCertificationModal) ?
                                                                        <>
                                                                            <AvGroup>
                                                                                <AvInput
                                                                                    placeholder="Enter certification title"
                                                                                    className="form-control"
                                                                                    name="title"
                                                                                    maxLength="100"
                                                                                    required />
                                                                                <AvFeedback>Required Field *</AvFeedback>
                                                                            </AvGroup>

                                                                            <AvGroup>
                                                                                <AvInput
                                                                                    placeholder="Enter certification description"
                                                                                    className="form-control"
                                                                                    type="textarea"
                                                                                    rows="3"
                                                                                    maxLength="100"
                                                                                    name="description"
                                                                                    required />
                                                                                <AvFeedback> Required Field *</AvFeedback>
                                                                            </AvGroup>
                                                                        </>
                                                                        :
                                                                        ""
                                            }

                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => this.setState({ openModal: false })}>Close</Button>
                                                <Button type="submit" variant="primary" >Save Changes</Button>
                                            </Modal.Footer>
                                        </Modal.Body>
                                    </AvForm>
                                </Modal>
                            </section>

                            <section className="dashboard-area business-detail">
                                <div className="y-page-link">
                                    <h2>Business Profile Summary
                                        <div className="info-box-overlay">
                                            <OverlayTrigger
                                                delay={200}
                                                trigger={["hover", "hover"]}
                                                placement={'auto'}
                                                overlay={popover('Business Profile Summary', 'Your name, basic business information and contact information is required for this area.')}
                                            >
                                                <FaInfo />
                                            </OverlayTrigger>
                                        </div>
                                        <img src={Complete} alt="complete" /></h2>
                                    <div className="edit-content">
                                        <button onClick={() => this.setState({ editProfileModal: !this.state.editProfileModal })} className="remove-btn-bg" >
                                            <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                        </button>
                                    </div>
                                    {(this.state.editProfileModal) ?
                                        <Collapse isOpen={this.state.editProfileModal}>
                                            <Card className="card-custom-1 tag-custom">
                                                <CardBody style={{ backgroundColor: "#F7F7F7" }}>
                                                    <AvForm onSubmit={this.handleSubmit}>
                                                        <>
                                                            <AvGroup>
                                                                <AvInput
                                                                    placeholder="Enter business name"
                                                                    className="form-control"
                                                                    name="business_name"
                                                                    value={this.state.business.business_name}
                                                                    required />
                                                                <AvFeedback> Required Field *</AvFeedback>
                                                            </AvGroup>

                                                            <AvField type="select"
                                                                name="business_category_id"
                                                                label=""
                                                                onChange={(e) => this.changeMainCategory(e.target.value)}
                                                                value={this.state.business.business_category_id}
                                                            >
                                                                <option disabled value='' >Primary Business  Industry</option>
                                                                {
                                                                    this.state.categories.map(cat => {
                                                                        return (
                                                                            <option key={cat.id} value={cat.id}>{cat.name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </AvField>



                                                            <AvGroup>
                                                                <AvField type="select"
                                                                    name="business_sub_category_id"
                                                                    label=""
                                                                    onChange={(e) => this.changeSubCategory(e.target.value)}
                                                                    value={this.state.business.business_sub_category_id}
                                                                    placeholder='Select subcategory'
                                                                    required
                                                                >
                                                                    <option disabled value='' >Specific Business Service</option>
                                                                    {
                                                                        this.state.subcategories.map(subcat => {
                                                                            return (
                                                                                <option key={subcat.id} value={subcat.id}>{subcat.name}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </AvField>
                                                                <AvFeedback> Required Field *</AvFeedback>
                                                            </AvGroup>
                                                            {/* <AvGroup>
                                                                <Select placeholder='Select features'
                                                                    options={this.state.filters}
                                                                    defaultValue={this.state.bSelectFilter}
                                                                    isMulti
                                                                    value={this.state.bSelectFilter}
                                                                    name="colors"
                                                                    required
                                                                    className="basic-multi-select mb-3"
                                                                    classNamePrefix="select"
                                                                    onChange={this.change}
                                                                />
                                                                <AvInput
                                                                    type="hidden"
                                                                    placeholder="Enter Amount"
                                                                    className="form-control"
                                                                    value={this.state.bFilter}
                                                                    name="category_filters"
                                                                    required />
                                                                <AvFeedback> Required Field *</AvFeedback>
                                                            </AvGroup> */}
                                                            {/* {(this.state.BAmount) ?
                                                                <AvGroup>
                                                                    <AvInput
                                                                        type="number"
                                                                        placeholder="Enter Amount"
                                                                        className="form-control"
                                                                        name="business_amount"
                                                                        value={this.state.business.business_amount}
                                                                        required />
                                                                    <AvFeedback> Required Field *</AvFeedback>
                                                                </AvGroup>
                                                                : ""} */}


                                                            <AvGroup>
                                                                {this.state.externalData ?
                                                                    <>
                                                                        <TagsInput inputProps={{ placeholder: 'Enter owner name', className: 'react-tagsinput-input tag12' }} value={this.state.tagsOwnerName} onChange={this.handleChangeOwner} />
                                                                    </>
                                                                    : ""
                                                                }
                                                                <AvInput
                                                                    type='hidden'
                                                                    placeholder="Enter owner name"
                                                                    className="form-control"
                                                                    name="owner_name"
                                                                    value={this.state.tagsOwnerName.toString(',')}
                                                                    required />
                                                                <AvFeedback> Required Field *</AvFeedback>
                                                            </AvGroup>

                                                            <AvGroup>
                                                                <AvInput
                                                                    placeholder="Enter business email"
                                                                    className="form-control"
                                                                    name="business_email"
                                                                    value={this.state.business.business_email}
                                                                    required />
                                                                <AvFeedback> Required Field *</AvFeedback>
                                                            </AvGroup>

                                                            <AvGroup>
                                                                <AvInput
                                                                    placeholder="(xxx) xxx-xxxx"
                                                                    className="form-control phone_re"
                                                                    name="phone_number"
                                                                    value={this.state.business.phone_number}
                                                                    validate={{ pattern: { value: /^\([0-9]{3}\)[-\s\.][0-9]{3}-[0-9]{4}$/ } }}
                                                                    required />
                                                                <AvFeedback> Required Field *</AvFeedback>
                                                            </AvGroup>

                                                            <AvGroup>
                                                                <AvInput
                                                                    placeholder="Enter website url"
                                                                    className="form-control"
                                                                    name="website"
                                                                    value={this.state.business.website}
                                                                    required />
                                                                <AvFeedback> Required Field *</AvFeedback>
                                                            </AvGroup>
                                                        </>
                                                        <div className="button-modal-footer">
                                                            <Button variant="secondary" onClick={() => this.setState({ editProfileModal: !this.state.editProfileModal })}>Close</Button>
                                                            <Button type="submit" variant="primary" >Save Changes</Button>
                                                        </div>
                                                    </AvForm>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                        :
                                        <ul>
                                            <li className="justify-content-between" key={0}>
                                                <span className="text-left"> Business Name</span>
                                                <span className="text-rigt">{this.state.business.business_name}</span>
                                            </li>
                                            <li className="justify-content-between" key={1}>
                                                <span className="text-left"> Primary Business  Industry</span>
                                                <span className="text-rigt">{this.state.business.main_category}</span>
                                            </li>
                                            <li className="justify-content-between" key={2}>
                                                <span className="text-left"> Specific Business Service</span>
                                                <span className="text-rigt">{this.state.business.sub_category}</span>
                                            </li>
                                            <li className="justify-content-between" key={3}>
                                                <span className="text-left"> Owner Name</span>
                                                <span className="text-rigt">{this.state.business.owner_name}</span>
                                            </li>
                                            <li className="justify-content-between" key={4}>
                                                <span className="text-left"> Email</span>
                                                <span className="text-rigt">{this.state.business.business_email}</span>
                                            </li>
                                            <li className="justify-content-between" key={5}>
                                                <span className="text-left"> Phone Number</span>
                                                <span className="text-rigt">{this.state.business.phone_number}</span>
                                            </li>
                                            <li className="justify-content-between" key={6}>
                                                <span className="text-left"> Website</span>
                                                <span className="text-rigt">
                                                    <a href={this.state.business.website} traget="_blank" >
                                                        {this.state.business.website}
                                                    </a>
                                                </span>
                                            </li>


                                        </ul>
                                    }
                                </div>
                            </section>
                            <section className="dashboard-area business-detail">
                                <div className="y-page-link">
                                    <h2>Business Description
                                        <div className="info-box-overlay">
                                            <OverlayTrigger
                                                delay={200}
                                                trigger={["hover", "hover"]}
                                                placement={'auto'}
                                                overlay={popover('Business Description',
                                                    'Please provide a brief description of your business and the product or service you provide in this section.')}
                                            >
                                                <FaInfo />
                                            </OverlayTrigger>
                                        </div>
                                        {this.state.complete_section.includes('description') ?
                                            <img src={Complete} alt="complete" />
                                            :
                                            <img src={Incomplete} alt="incomplete" />
                                        }
                                    </h2>
                                    <div className="edit-content">
                                        <button onClick={() => this.setState({ editDescriptionModal: !this.state.editDescriptionModal })} className="remove-btn-bg" >
                                            <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                        </button>
                                    </div>
                                    {(this.state.editDescriptionModal) ?
                                        <Collapse isOpen={this.state.editDescriptionModal}>
                                            <Card className="card-custom-1">
                                                <CardBody >
                                                    <AvForm onSubmit={this.handleSubmit}>
                                                        <AvGroup>
                                                            <AvInput
                                                                placeholder="Enter business description"
                                                                className="form-control"
                                                                type="textarea"
                                                                rows="8"
                                                                name="business_description"
                                                                value={this.state.business.business_description}
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup>
                                                        <div className="button-modal-footer">
                                                            <Button variant="secondary" onClick={() => this.setState({ editDescriptionModal: !this.state.editDescriptionModal })}>Close</Button>
                                                            <Button type="submit" variant="primary" >Save Changes</Button>
                                                        </div>
                                                    </AvForm>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                        :
                                        <p> {this.state.business.business_description} </p>
                                    }
                                </div>
                            </section>
                            <section className="dashboard-area business-detail social-media">
                                <div className="y-page-link">
                                    <h2>Social Media
                                        <div className="info-box-overlay">
                                            <OverlayTrigger
                                                delay={200}
                                                trigger={["hover", "hover"]}
                                                placement={'auto'}
                                                overlay={popover('Social Media',
                                                    'Add your Facebook, Instagram, and LinkedIn accounts by pasting their page urls in this section.')}
                                            >
                                                <FaInfo />
                                            </OverlayTrigger>
                                        </div>

                                        {this.state.complete_section.includes('social media') ?
                                            <img src={Complete} alt="complete" />
                                            :
                                            <img src={Incomplete} alt="incomplete" />
                                        }
                                    </h2>
                                    <div className="edit-content">
                                        <button onClick={() => this.setState({ editSocialMediaModal: !this.state.editSocialMediaModal })} className="remove-btn-bg" >
                                            <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                        </button>
                                    </div>
                                    {(this.state.editSocialMediaModal) ?
                                        <Collapse isOpen={this.state.editSocialMediaModal}>
                                            <Card className="card-custom-1">
                                                <CardBody >
                                                    <AvForm onSubmit={this.handleSubmit}>
                                                        <>
                                                            <AvGroup>
                                                                <AvInput
                                                                    placeholder="Enter facebook profile url"
                                                                    className="form-control"
                                                                    name="fb_url"
                                                                    type="url"
                                                                    value={this.state.socialMedia.fb_url}
                                                                />
                                                                <AvFeedback> Enter a url *</AvFeedback>
                                                            </AvGroup>
                                                            <AvGroup>
                                                                <AvInput
                                                                    placeholder="Enter instagram profile url"
                                                                    className="form-control"
                                                                    name="insta_url"
                                                                    type="url"
                                                                    value={this.state.socialMedia.insta_url}
                                                                />
                                                                <AvFeedback> Enter a url *</AvFeedback>
                                                            </AvGroup>

                                                            <AvGroup>
                                                                <AvInput
                                                                    placeholder="Enter LinkedIn profile url"
                                                                    className="form-control"
                                                                    name="linkedin_url"
                                                                    type="url"
                                                                    value={this.state.socialMedia.linkedin_url}
                                                                />
                                                                <AvFeedback> Enter a url *</AvFeedback>
                                                            </AvGroup>
                                                        </>
                                                        <div className="button-modal-footer">
                                                            <Button variant="secondary" onClick={() => this.setState({ editSocialMediaModal: !this.state.editSocialMediaModal })}>Close</Button>
                                                            <Button type="submit" variant="primary" >Save Changes</Button>
                                                        </div>
                                                    </AvForm>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                        :
                                        <ul>

                                            <li className="justify-content-between" key={0}>
                                                <span className="text-left">Business Facebook</span>
                                                <span className="text-rigt">
                                                    {this.state.socialMedia.fb_url}
                                                </span>
                                            </li>

                                            <li className="justify-content-between" key={1}>
                                                <span className="text-left">Business Instagram</span>
                                                <span className="text-rigt">
                                                    {this.state.socialMedia.insta_url}
                                                </span>
                                            </li>

                                            <li className="justify-content-between" key={2}>
                                                <span className="text-left"> Business LinkedIn</span>
                                                <span className="text-rigt">
                                                    {this.state.socialMedia.linkedin_url}
                                                </span>
                                            </li>
                                            {/* {this.state.socialmedia.map((item, index) => {
                                            return (
                                                <li className="justify-content-between" key={index}>
                                                    <span className="text-left"> {item.text}</span>
                                                    <span className="text-rigt">
                                                        {item.desc}

                                                    </span>

                                                </li>
                                            )
                                        })} */}
                                        </ul>
                                    }
                                </div>
                            </section>

                            <section className="dashboard-area business-detail">
                                <div className="y-page-link">
                                    <h2>Video
                                        <div className="info-box-overlay">
                                            <OverlayTrigger
                                                delay={200}
                                                trigger={["hover", "hover"]}
                                                placement={'auto'}
                                                overlay={popover('Video',
                                                    'Youtube and Vimeo video urls can be pasted in this section.')}
                                            >
                                                <FaInfo />
                                            </OverlayTrigger>
                                        </div>
                                        {this.state.complete_section.includes('video') ?
                                            <img src={Complete} alt="complete" />
                                            :
                                            <img src={Incomplete} alt="incomplete" />
                                        }
                                    </h2>
                                    <div className="edit-content">
                                        <button onClick={() => this.setState({ editVideoModal: !this.state.editVideoModal })} className="remove-btn-bg" >
                                            <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                        </button>
                                    </div>
                                    {(this.state.editVideoModal) ?
                                        <Collapse isOpen={this.state.editVideoModal}>
                                            <Card className="card-custom-1">
                                                <CardBody >
                                                    <AvForm onSubmit={this.handleSubmit}>
                                                        <AvGroup>
                                                            <AvInput
                                                                placeholder="Enter video url"
                                                                className="form-control"
                                                                type="url"
                                                                name="business_video"
                                                                value={this.state.business.business_video}
                                                            />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </AvGroup>
                                                        <div className="button-modal-footer">
                                                            <Button variant="secondary" onClick={() => this.setState({ editVideoModal: !this.state.editVideoModal })}>Close</Button>
                                                            <Button type="submit" variant="primary" >Save Changes</Button>
                                                        </div>
                                                    </AvForm>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                        :

                                        <a href={`${this.state.business.business_video}`} className="video" target="_blank">
                                            {
                                                this.state.business.business_video ?
                                                    this.state.business.business_video.replace(/(^\w+:|^)\/\//, '').replace(/\/+$/, '')
                                                    : ""
                                            }
                                        </a>
                                    }
                                </div>
                            </section>

                            <section className="dashboard-area business-detail">
                                <div className="y-page-link">
                                    <h2></h2>
                                    <div className="edit-content">
                                        <button onClick={() => this.setState({ AddLocationModal: !this.state.AddLocationModal, })} className="remove-btn-bg" >
                                            <span className="dashboard-icon">Add Location  <RiAddLine /></span>
                                        </button>
                                        <button onClick={() => this.setState({ editAddressWorkingModal: !this.state.editAddressWorkingModal, })} className="remove-btn-bg" >
                                            <span className="dashboard-icon margin-remove-top">Edit <img src={Edit} alt="Copy" /></span>
                                        </button>

                                    </div>

                                    {(this.state.editAddressWorkingModal) ?
                                        <Collapse isOpen={this.state.editAddressWorkingModal}>
                                            <Card className="card-custom-1">
                                                <CardBody style={{ backgroundColor: "#F7F7F7" }}>
                                                    <AvForm onSubmit={this.handleSubmit}>
                                                        <div className="row online-business margin-bottom-60px">
                                                            <div className="col-md-6 margin-bottom-60px">
                                                                <div className="custom-checkbox" key={0}>
                                                                    <input defaultChecked={this.state.business.online_business == 'No' ? true : false} type="checkbox" id={'chb3-'} onChange={this.onlineBusiness} />
                                                                    <label htmlFor={'chb3-'}>
                                                                        Online Business
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.onlineBusiness ?
                                                                    <div className="col-md-6">
                                                                        <AvGroup>
                                                                            <AvInput
                                                                                placeholder="Enter zip code"
                                                                                className="form-control"
                                                                                name="zip_code"
                                                                                value={this.state.business.zip_code}
                                                                                onKeyUp={this.ZipCode}
                                                                            />
                                                                            <AvFeedback>Required Field *</AvFeedback>
                                                                        </AvGroup>
                                                                        {/* <input type="text" id="one" className="form-control" onKeyPress={this.ZipCode} /> */}
                                                                    </div>
                                                                    : ""
                                                            }

                                                            <AvGroup>
                                                                <AvInput
                                                                    type="hidden"
                                                                    placeholder="Enter zip code"
                                                                    className="form-control"
                                                                    name="online_business"
                                                                    value={this.state.onlineBusiness == true ? 'No' : 'Yes'}
                                                                />
                                                                <AvFeedback>Required Field *</AvFeedback>
                                                            </AvGroup>

                                                            {
                                                                this.state.onlineBusiness ?

                                                                    <div className="col-md-12">
                                                                        <Autosuggest
                                                                            suggestions={suggestions}
                                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                            getSuggestionValue={getSuggestionValue}
                                                                            renderSuggestion={renderSuggestion}
                                                                            inputProps={inputProps}

                                                                        />
                                                                    </div>

                                                                    : ""
                                                            }
                                                            <Col lg={12} md={12} >
                                                                <Select placeholder='Select neighborhood'
                                                                    options={this.state.neighborhoods}
                                                                    name="colors"
                                                                    value={this.state.seletNeighborh}
                                                                    className="basic-multi-select mt-3 mb-3"
                                                                    classNamePrefix="select"
                                                                    onChange={this.changeNeighborhood}
                                                                />
                                                            </Col>

                                                            <Col lg={6} md={6} >
                                                                <GeneralSearchMap
                                                                    onSelectLanguage={this.handler}
                                                                    google={this.props.google}
                                                                    center={{
                                                                        lat: this.state.business.business_lat, lng: this.state.business.business_long,
                                                                        address: this.state.business.business_address
                                                                    }}
                                                                    direction={this.state.businessdirection}
                                                                    onlineBusiness={this.state.onlineBusiness}
                                                                    onlineAddress={this.state.business_loc}
                                                                    height='362px'
                                                                    zoom={15}

                                                                />
                                                            </Col>
                                                            <Col lg={6} md={6} >
                                                                {
                                                                    !this.state.onlineBusiness ?
                                                                        <>
                                                                            {
                                                                                days.map((d, j) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div key={j} className="row">
                                                                                                <div className="col-md-4">
                                                                                                    <AvGroup>
                                                                                                        <AvInput
                                                                                                            placeholder="Enter business day"
                                                                                                            className="form-control"
                                                                                                            name={'day_' + d.key}
                                                                                                            value={d.day}
                                                                                                            readOnly
                                                                                                            required />
                                                                                                        <AvFeedback> Required Field *</AvFeedback>
                                                                                                    </AvGroup>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <AvField type="select"
                                                                                                        name={'start_time_' + d.key}
                                                                                                        label=""
                                                                                                        value={(this.state.editBusinessWorkingHours[j]) ? this.state.editBusinessWorkingHours[j].start_time : "Closed"}
                                                                                                    >
                                                                                                        <option value="Closed">Closed</option>
                                                                                                        {
                                                                                                            hours.map(hour => {
                                                                                                                var parsedDate = moment(`${hour}:00`, "H m");
                                                                                                                var parsedDate1 = moment(`${hour}:30`, "H m");
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <option key={parsedDate.format("hh:mm A")} value={parsedDate.format("hh:mm A")}>{parsedDate.format("hh:mm A")}</option>
                                                                                                                        <option key={parsedDate1.format("hh:mm A")} value={parsedDate1.format("hh:mm A")}>{parsedDate1.format("hh:mm A")}</option>
                                                                                                                    </>
                                                                                                                )
                                                                                                            })
                                                                                                        }


                                                                                                    </AvField>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <AvField type="select"
                                                                                                        name={'end_time_' + d.key}
                                                                                                        label=""
                                                                                                        value={(this.state.editBusinessWorkingHours[j]) ? this.state.editBusinessWorkingHours[j].end_time : "Closed"}
                                                                                                    >
                                                                                                        <option value="Closed">Closed</option>
                                                                                                        {
                                                                                                            hours.map(hour => {
                                                                                                                var parsedDate = moment(`${hour}:00`, "H m");
                                                                                                                var parsedDate1 = moment(`${hour}:30`, "H m");
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <option key={parsedDate.format("hh:mm A")} value={parsedDate.format("hh:mm A")}>{parsedDate.format("hh:mm A")}</option>
                                                                                                                        <option key={parsedDate1.format("hh:mm A")} value={parsedDate1.format("hh:mm A")}>{parsedDate1.format("hh:mm A")}</option>
                                                                                                                    </>
                                                                                                                )
                                                                                                            })
                                                                                                        }


                                                                                                    </AvField>
                                                                                                </div>
                                                                                            </div>

                                                                                        </>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </>
                                                                        : ""
                                                                }
                                                            </Col>


                                                        </div>


                                                        <div className="button-modal-footer">
                                                            <Button variant="secondary" onClick={() => this.setState({ editAddressWorkingModal: !this.state.editAddressWorkingModal })}>Close</Button>
                                                            <Button type="submit" variant="primary" >Save Changes</Button>
                                                        </div>
                                                    </AvForm>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                        : ""
                                    }
                                    {(!this.state.editAddressWorkingModal) ?
                                        <div className="row">
                                            <div className="col-lg-6">

                                                <div className="listing-map gmaps">
                                                    <h2 className="widget-title">
                                                        {this.state.mapTitle}
                                                        <div className="info-box-overlay">
                                                            <OverlayTrigger
                                                                delay={200}
                                                                trigger={["hover", "hover"]}
                                                                placement={'auto'}
                                                                overlay={popover('Location & Hours',
                                                                    'If you are an Online business, provide your zip code and neighborhood.  Brick & Mortar businesses please provide your hours of operation and neighborhood. You can also add multiple locations.')}
                                                            >
                                                                <FaInfo />
                                                            </OverlayTrigger>
                                                        </div>
                                                        {this.state.complete_section.includes('location and hours') ?
                                                            <img src={Complete} alt="complete" />
                                                            :
                                                            <img src={Incomplete} alt="incomplete" />
                                                        }
                                                    </h2>
                                                    {/* <div className="edit-content">
                                                        <button onClick={() => this.setState({ addCertificationModal: false, editAddressModal: true, editWorkingHourModal: false, editKeywordModal: false, editDescriptionModal: false, editProfileModal: false, openModal: true, editUrlModal: false })} className="remove-btn-bg" >
                                                            <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                                        </button>
                                                    </div> */}
                                                    <div className="get-direction">
                                                        <h3 className="heading-direction">
                                                            {this.state.business.suite_number} <br />
                                                            {this.state.business.business_address ? this.state.business.business_address.replace(", USA", "") : ""}
                                                        </h3>
                                                        <p className="ShopAddress mb-10">{this.state.business.business_neighborhoods}</p>
                                                    </div>
                                                    <div className="google-map">
                                                        {(this.state.externalData) ? <GeneralMap animation={0} state={this.state.business} /> : ""}
                                                    </div>

                                                    {(this.state.AddLocationModal) ?
                                                        <Collapse isOpen={this.state.AddLocationModal}>
                                                            <Card className="card-custom-2">
                                                                <CardBody >
                                                                    <AvForm onSubmit={this.handleSubmit}>
                                                                        <>
                                                                            <div className="row">
                                                                                <div className="col-lg-12">
                                                                                    <div className="input-box">
                                                                                        <AvGroup>
                                                                                            <label>Business Address</label>
                                                                                            <Autocomplete
                                                                                                apiKey={AutoApiKey}
                                                                                                placeholder="Enter Other Location"
                                                                                                style={{ width: '100%' }}
                                                                                                onPlaceSelected={this.onPlaceSelected}
                                                                                                onKeyPress={e => {
                                                                                                    if (e.key === 'Enter') e.preventDefault();
                                                                                                }}
                                                                                                className="form-control"
                                                                                                fields={[
                                                                                                    'address_components',
                                                                                                    'geometry.location',
                                                                                                    'place_id',
                                                                                                    'formatted_address'
                                                                                                ]}
                                                                                                types={[]}
                                                                                                componentRestrictions={{ country: "us" }}
                                                                                            />
                                                                                            <AvInput
                                                                                                type="hidden"
                                                                                                className="form-control"
                                                                                                name="business_location"
                                                                                                value={this.state.address}
                                                                                                required />
                                                                                            <AvFeedback>This field is required.*</AvFeedback>
                                                                                        </AvGroup>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <div className="input-box1">
                                                                                        <AvGroup>
                                                                                            <AvInput
                                                                                                type="hidden"
                                                                                                className="form-control"
                                                                                                name="business_lat"
                                                                                                onChange={this.onLocationChange}
                                                                                                value={this.state.business_lat}
                                                                                                required />
                                                                                            <AvFeedback> Required Field *</AvFeedback>
                                                                                            <AvInput
                                                                                                type="hidden"
                                                                                                className="form-control"
                                                                                                name="business_long"
                                                                                                onChange={this.onLocationChange}
                                                                                                value={this.state.business_long}
                                                                                                required />
                                                                                            <AvFeedback> Required Field *</AvFeedback>
                                                                                            <AvInput
                                                                                                placeholder="City"
                                                                                                className="form-control"
                                                                                                name="business_city"
                                                                                                onChange={this.onLocationChange}
                                                                                                readOnly={true}
                                                                                                value={this.state.city}
                                                                                                required />
                                                                                            <AvFeedback> Required Field *</AvFeedback>
                                                                                        </AvGroup>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="input-box1">
                                                                                        <AvGroup>
                                                                                            <AvInput
                                                                                                placeholder="State"
                                                                                                className="form-control"
                                                                                                name="business_state"
                                                                                                onChange={this.onLocationChange}
                                                                                                readOnly={true}
                                                                                                value={this.state.state}
                                                                                                required />
                                                                                            <AvFeedback> Required Field *</AvFeedback>
                                                                                        </AvGroup>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="input-box1">
                                                                                        <AvGroup>
                                                                                            <AvInput
                                                                                                placeholder="Zip Code"
                                                                                                className="form-control"
                                                                                                name="business_zip_code"
                                                                                                onChange={this.onLocationChange}
                                                                                                readOnly={true}
                                                                                                value={this.state.zipcode}
                                                                                            />
                                                                                            <AvFeedback> Required Field *</AvFeedback>
                                                                                        </AvGroup>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        <div className="button-modal-footer">
                                                                            <Button variant="secondary" onClick={() => this.setState({ AddLocationModal: !this.state.AddLocationModal })}>Close</Button>
                                                                            <Button type="submit" variant="primary" >Save Changes</Button>
                                                                        </div>
                                                                    </AvForm>
                                                                </CardBody>
                                                            </Card>
                                                        </Collapse>
                                                        :
                                                        <>
                                                            {this.state.businessAddresses.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="mt-3">
                                                                        <div className="get-direction">
                                                                            <h3 className="heading-direction">
                                                                                {item.business_address.replace(", USA", "")}
                                                                            </h3>
                                                                        </div>
                                                                        <button onClick={() => this.removeOtherLocation(item.id)} className="remove-btn-bg pull-right" >
                                                                            <span className=""><RiDeleteBinLine /></span>
                                                                        </button>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="listing-map gmaps">
                                                    <h2 className="widget-title">
                                                        &nbsp;
                                                    </h2>

                                                    {
                                                        this.state.business.online_business == 'No' ?

                                                            <div className="online-business-images">
                                                                <img src={Weavaillble} />
                                                            </div>
                                                            :
                                                            <>
                                                                {(this.state.externalData) ? <WidgetOpenHours state={this.state.workingHours} /> : ""}

                                                            </>
                                                    }



                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </section>
                            <section className="two-col">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <section className="dashboard-area business-detail">
                                            <div className="y-page-link height-400">
                                                <div className="listing-map gmaps">
                                                    <h2>Certifications
                                                        <div className="info-box-overlay">
                                                            <OverlayTrigger
                                                                delay={200}
                                                                trigger={["hover", "hover"]}
                                                                placement={'auto'}
                                                                overlay={popover('Certifications',
                                                                    'Provide customers with information about any certifications or licenses that you hold for your business.')}
                                                            >
                                                                <FaInfo />
                                                            </OverlayTrigger>
                                                        </div>
                                                        {this.state.complete_section.includes('certification') ?
                                                            <img src={Complete} alt="complete" />
                                                            :
                                                            <img src={Incomplete} alt="incomplete" />
                                                        }
                                                    </h2>
                                                    <div className="edit-content">
                                                        <button onClick={() => this.setState({ addCertificationModal: !this.state.addCertificationModal })} className="remove-btn-bg" >
                                                            <span className="dashboard-icon">Add <RiAddLine /></span>
                                                        </button>
                                                    </div>
                                                    {(this.state.addCertificationModal) ?
                                                        <Collapse isOpen={this.state.addCertificationModal}>
                                                            <Card className="card-custom-1">
                                                                <CardBody >
                                                                    <AvForm onSubmit={this.handleSubmit}>
                                                                        <>
                                                                            <AvGroup>
                                                                                <AvInput
                                                                                    placeholder="Enter certification title"
                                                                                    className="form-control"
                                                                                    name="title"
                                                                                    maxLength="100"
                                                                                    required />
                                                                                <AvFeedback>Required Field *</AvFeedback>
                                                                            </AvGroup>

                                                                            <AvGroup>
                                                                                <AvInput
                                                                                    placeholder="Enter certification description"
                                                                                    className="form-control"
                                                                                    type="textarea"
                                                                                    rows="3"
                                                                                    maxLength="100"
                                                                                    name="description"
                                                                                    required />
                                                                                <AvFeedback> Required Field *</AvFeedback>
                                                                            </AvGroup>

                                                                            <AvGroup>
                                                                                <AvInput
                                                                                    placeholder="Enter certification year"
                                                                                    className="form-control"
                                                                                    name="year"
                                                                                    maxLength="4"
                                                                                    required />
                                                                                <AvFeedback>Required Field *</AvFeedback>
                                                                            </AvGroup>
                                                                        </>
                                                                        <div className="button-modal-footer">
                                                                            <Button variant="secondary" onClick={() => this.setState({ addCertificationModal: !this.state.addCertificationModal })}>Close</Button>
                                                                            <Button type="submit" variant="primary" >Save Changes</Button>
                                                                        </div>
                                                                    </AvForm>
                                                                </CardBody>
                                                            </Card>
                                                        </Collapse>
                                                        :
                                                        <>
                                                            {this.state.businessCertification.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="display-flex">
                                                                        <div className="certificate-title">
                                                                            <h2>{item.title}</h2>
                                                                            <p>{item.description}</p>
                                                                            <p>{item.year}</p>
                                                                        </div>
                                                                        <button onClick={() => this.removeCertification(item.id)} className="remove-btn-bg pull-right" >
                                                                            <span className=""><RiDeleteBinLine /></span>
                                                                        </button>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="col-lg-6">
                                        <section className="dashboard-area business-detail">
                                            <div className="y-page-link height-400">
                                                <h2>Keywords
                                                    <div className="info-box-overlay">
                                                        <OverlayTrigger
                                                            delay={200}
                                                            trigger={["hover", "hover"]}
                                                            placement={'auto'}
                                                            overlay={popover('Keywords',
                                                                'Increase the chances of your appearance in our search by providing any keywords related to your product or service in this section.')}
                                                        >
                                                            <FaInfo />
                                                        </OverlayTrigger>
                                                    </div>
                                                    {this.state.complete_section.includes('keywords') ?
                                                        <img src={Complete} alt="complete" />
                                                        :
                                                        <img src={Incomplete} alt="incomplete" />
                                                    }
                                                </h2>
                                                <div className="edit-content">
                                                    <button onClick={() => this.setState({ editKeywordModal: !this.state.editKeywordModal })} className="remove-btn-bg" >
                                                        <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                                    </button>
                                                </div>
                                                {(this.state.editKeywordModal) ?
                                                    <Collapse isOpen={this.state.editKeywordModal}>
                                                        <Card className="card-custom-1">
                                                            <CardBody >
                                                                <AvForm onSubmit={this.handleSubmit}>
                                                                    <>
                                                                        {/* addKeys={[9, 13, 32]} */}
                                                                        <TagsInput inputProps={{ placeholder: 'Press enter to add Keyword', className: 'react-tagsinput-input' }} value={this.state.tagsKeyword} onChange={this.handleChange} />
                                                                    </>
                                                                    <div className="button-modal-footer">
                                                                        <Button variant="secondary" onClick={() => this.setState({ editKeywordModal: !this.state.editKeywordModal })}>Close</Button>
                                                                        <Button disabled={this.state.tagsKeyword == '' ? true : false} type="submit" variant="primary" >Save Changes</Button>
                                                                    </div>
                                                                </AvForm>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                    :
                                                    <div className="keywords">

                                                        <ul>
                                                            {(this.state.keywords && this.state.keywords != '') ?

                                                                this.state.keywords.split(',').map((item, index) => {
                                                                    return (
                                                                        <li key={index}><Link to="#" >{item}</Link></li>
                                                                    )
                                                                })
                                                                : ""


                                                            }
                                                        </ul>
                                                    </div>
                                                }

                                            </div>
                                        </section>
                                    </div>


                                </div>
                                <ToastContainer />
                            </section>


                        </Col>

                    </Row >

                    <div className="modal-form text-center warning-list ">
                        <div className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                            <Modal className="WarningAlertModal"
                                show={this.state.WarningAlert}
                                onHide={this.onHide}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                size="md" >
                                <div className="modal-content warning-list  p-4">
                                    <div className="modal-top border-0 mb-4 p-0">
                                        <div className="alert-content">
                                            <div className="warning">
                                                <span className="la warning-icon"><AiOutlineExclamationCircle /></span>
                                            </div>
                                            <h2 className="modal-title mt-2 mb-1">Welcome to Katika</h2>
                                            <h4 className="modal-title mt-2 mb-1">Please fill out the starred (<img className="dashboard-alert-satrted" src={Starfilled} />) areas of your profile. Once 70% of your business profile is completed, your page will be live!</h4>
                                        </div>
                                    </div>
                                    <div className="btn-box">
                                        <button onClick={() => this.setState({ WarningAlert: false })} type="button" className="border-0 button-success mr-1" data-dismiss="modal">
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>


                    <Modal show={this.state.AccountPayModal}
                        onHide={this.onHide}
                        size="xl"
                        className="DashboardAccoundModal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                    >

                        <Modal.Header closeButton>
                            <Modal.Title>Choose a plan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Account to={'fromDashboard'} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ AccountPayModal: false })}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </Container >

                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;