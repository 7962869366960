import React from 'react';
import ContentLoader from 'react-content-loader';



const RandomLoaders = (props) => {
    if (props.page == 1) {
        return <>
            <ContentLoader viewBox="0 0 500 450" height={450} width={500}
                backgroundColor="#eddede"
                foregroundColor="#9c9c9c"
            >
                <rect x="16" y="17" rx="0" ry="0" width="500" height="250" />
                <circle cx="35" cy="298" r="20" />
                <rect x="69" y="279" rx="2" ry="2" width="275" height="15" />
                <rect x="69" y="303" rx="2" ry="2" width="140" height="15" />
                <rect x="16" y="330" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="350" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="370" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="390" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="410" rx="3" ry="3" width="360" height="10" />
            </ContentLoader>

            <ContentLoader viewBox="0 0 500 450" height={450} width={500}
                backgroundColor="#eddede"
                foregroundColor="#9c9c9c"
            >
                <rect x="16" y="17" rx="0" ry="0" width="500" height="250" />
                <circle cx="35" cy="298" r="20" />
                <rect x="69" y="279" rx="2" ry="2" width="275" height="15" />
                <rect x="69" y="303" rx="2" ry="2" width="140" height="15" />
                <rect x="16" y="330" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="350" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="370" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="390" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="410" rx="3" ry="3" width="360" height="10" />
            </ContentLoader>


        </>;
    } else if (props.page == 2) {
        return <>
            

            <ContentLoader
                width={750}
                height={250}
                viewBox="0 0 750 250"
                backgroundColor="#eddede"
                foregroundColor="#9c9c9c"
                {...props}
            >
                <rect x="0" y="5" rx="2" ry="2" width="244" height="200" />
                <rect x="260" y="5" rx="0" ry="0" width="300" height="11" />
                <rect x="260" y="20" rx="0" ry="0" width="150" height="30" />
                <rect x="260" y="60" rx="0" ry="0" width="200" height="11" />
                <rect x="260" y="90" rx="0" ry="0" width="420" height="10" />
                <rect x="260" y="105" rx="0" ry="0" width="420" height="10" />
                <rect x="260" y="120" rx="0" ry="0" width="420" height="10" />
                <rect x="700" y="5" rx="0" ry="0" width="50" height="10" />
                <rect x="669" y="17" rx="0" ry="0" width="150" height="10" />

            </ContentLoader>

            <ContentLoader
                width={750}
                height={250}
                viewBox="0 0 750 250"
                backgroundColor="#eddede"
                foregroundColor="#9c9c9c"
                {...props}
            >
                <rect x="0" y="5" rx="2" ry="2" width="244" height="200" />
                <rect x="260" y="5" rx="0" ry="0" width="300" height="11" />
                <rect x="260" y="20" rx="0" ry="0" width="150" height="30" />
                <rect x="260" y="60" rx="0" ry="0" width="200" height="11" />
                <rect x="260" y="90" rx="0" ry="0" width="420" height="10" />
                <rect x="260" y="105" rx="0" ry="0" width="420" height="10" />
                <rect x="260" y="120" rx="0" ry="0" width="420" height="10" />
                <rect x="700" y="5" rx="0" ry="0" width="50" height="10" />
                <rect x="669" y="17" rx="0" ry="0" width="150" height="10" />

            </ContentLoader>
        </>
    }
};

export default RandomLoaders;
