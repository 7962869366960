import React, { useEffect, useState } from 'react'
// import HomePageHeader from '../../components/business/common/PageHeader'
import HomePageHeader from '../../components/webapp_view/common/Navbar'
import BannerOne from '../../components/business/BannerOne'
import BannerMobile from '../../components/business/BannerMobile'
import Testimonial from '../../components/business/Testimonial'
import TestimonialTwo from '../../components/business/TestimonialTwo'
import { Row, Col, Tab, Nav } from "react-bootstrap";
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import { Link } from 'react-router-dom'
import kVideo from "../../assets/images/k-video.png";
import SlideOne from '../../assets/images/SlideOne.png';
import SlideTwo from '../../assets/images/SlideTwo.png';
import SlideThree from '../../assets/images/SlideThree.png';
import Community from '../../assets/images/community.png';
import BizOne from '../../assets/images/BizOne.png';
import BizTwo from '../../assets/images/BizTwo.png';
import BizThree from '../../assets/images/BizThree.png';
import LandingTopLogo from '../../assets/images/landing-top-logo.png';
import MainTopImg from '../../assets/images/maintopimg.png';
import VideoPlaceHolder from '../../assets/images/video-placeholder.png';
import ImgPlaceHolder from '../../assets/images/imgplaceholder.png';
import WorksPlaceHolder from '../../assets/images/worksimgplace.png';
import Inputmask from "inputmask";
import IMG1 from '../../assets/2024-01-12/IMG-20240112-WA0017.jpg';
import IMG2 from '../../assets/2024-01-12/IMG-20240112-WA0018.jpg';
import IMG3 from '../../assets/2024-01-12/IMG-20240112-WA0019.jpg';
import IMG4 from '../../assets/2024-01-12/IMG-20240112-WA0020.jpg';
import IMG5 from '../../assets/2024-01-12/IMG-20240112-WA0021.jpg';
import IMG6 from '../../assets/2024-01-12/IMG-20240112-WA0022.jpg';
import IMG7 from '../../assets/2024-01-12/IMG-20240112-WA0023.jpg';
import IMG8 from '../../assets/2024-01-12/IMG-20240112-WA0024.jpg';



import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { ApiUrl, sellerUrl } from '../../config'
import { random_item } from '../../useToken'
import axios from 'axios'
import { BiCheck } from 'react-icons/bi'
import { AvForm, AvInput } from 'availity-reactstrap-validation'
import { ToastContainer, toast } from 'react-toastify'

const Business = () => {

    const [items, setItems] = useState([
        {
            title: 'What business types are eligible for Katika business?',
            desc: `Businesses that register on Katika must be owned (50% +)
                by a person of African Descent. If you have never marked down Black,
                African or Caribbean on any type of application or census, 
                you do not qualify to be a business on Katika. Individuals with multi-level 
                marketing companies such as Herbalife, Total Life Changes, and ACN are not permitted to register on Katika.
                <br> <br> For-profit, non-profit and government agencies can create accounts on Katika.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'What are the benefits of joining Katika as a business?',
            desc: `When you join Katika for Business, you are connecting to an audience that is 
                    constantly seeking your products and services. We are focused on connecting local consumer to the businesses in
                    their neighborhoods or adjacent. We provide exposure for your brand, send information about funding opportunities
                    and events to participate in. Katika is working to facilitate dollars staying within communities for a longer 
                    period of time by making it easy for people to find local services and products in their neighborhoods.
                    Katika also provides educational resources to help close the information and education gap between business owners.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'How to join Katika as a business?',
            desc: `When you join Katika for Business, you are connecting to an audience that is constantly seeking your
             products and services. We are focused on connecting local consumer to the businesses in their
              neighborhoods or adjacent. We provide exposure for your brand, send information about funding
               opportunities and events to participate in. Katika is working to facilitate dollars staying 
               within communities for a longer period of time by making it easy for people to find local
                services and products in their neighborhoods. Katika also provides educational resources 
                to help close the information and education gap between business owners.<br>  <br>
                1. Go to <a target="_blank" href="${sellerUrl}"> ${sellerUrl} </a> and select Create Your Free Business Page <br>  <br>
                2. Fill out the organization's information on the 2 forms filling out your personal and business information.
                You will need to go to the email address you listed for the business and click on the confirmatin link 
                inside of the email to verify your account. <br>  <br> 
                3. Login to your account from <a  target="_blank" href="${sellerUrl}/sign-in" >${sellerUrl}/sign-in</a> and finish populating 
                your business profile. Your page will become live when your profile has 70% of the informatin filled out.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'How to claim your business page',
            desc: `Business information on your existing Katika for Business page was sourced from 3rd party public data, 
            or Katika Team Members who've created your profile. Your login information was sent to you when the profile was created. 
            If you need to claim your business page, please email us on our Contact Page.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'How to post on Katika as a business?',
            desc: `Businesses with a paid account can post to our neighborhood newsfeed called
             “The Block”. In our newsfeed businesses can create 4 types of post: General announcements, Useful Information, Events, and Promotions.
             To post on Katika as a business, following the steps below: <br> <br>
            1. Log in to your Katika for Business Page.<br> <br>
            2. Head to the ‘Create New Post’  tab in the left menu.Select the pencil icon and enter the zip code that you want to target with your post.<br> <br>
            3. Select your Post Type, and enter a title which describes the post, followed by a detailed description.<br> <br>
            4. You have the option to add a link to take your customer to a specific destination.<br> <br>
            5. If you use entered a link, then you will need to select a button option from the drop down menu.<br> <br>
            6. You must add a graphic or video to your post.<br> <br>
            7. Select Post to send your post to the newsfeed wall.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },

        // {
        //     title: 'Is there a phone app for Katika?',
        //     desc: 'The Android apps for Katika will be available in October. The iOS apps will be available by the end of the year. For general users, the app is called Katika. For business owners, the app is called Katika for Business.',
        //     plus: <AiOutlinePlus />,
        //     minus: <AiOutlineMinus />,
        //     cardClass: 'mb-3'
        // },
        // {
        //     title: 'How do I get paid?',
        //     desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text',
        //     plus: <AiOutlinePlus />,
        //     minus: <AiOutlineMinus />,
        //     cardClass: 'mb-3'
        // },
        // {
        //     title: 'How do I sell my products in other Katika stores?',
        //     desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text',
        //     plus: <AiOutlinePlus />,
        //     minus: <AiOutlineMinus />,
        //     cardClass: ''
        // },

    ])
    const [monthly, setPlans] = useState([]);
    const [annually, setAnnually] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getPlan();
        Inputmask({ "mask": "(999) 999-9999" }).mask('.phone');
    }, []);

    const getPlan = () => {

        axios.post(ApiUrl + 'getPlans', {}, {
            headers: {
                //'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                setPlans(res.data.monthlyPlan);
                setAnnually(res.data.annualyPlan);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSubmit = (event, values) => {
        axios.post(ApiUrl + 'funnel', values, {
            headers: {
                'Authorization': `Bearer`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    setLoader(false);
                    setTimeout(() => {
                        setLoader(true);
                    }, 200);
                } else {

                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    return (
        <main className="BusinessHome BizHome LandingPage">
            <div className='LandingTopBar text-center'>
                <p>Welcome to Katika - Your Content Creation Partner</p>
            </div>
            {/* Header */}
            {/* <HomePageHeader /> */}

            <div className='d-lg-block d-xl-block'>
                <ToastContainer />

                {/* Hero Banner */}
                {/* <BannerOne /> */}
                <div className='LandingPageMainBanner'>
                    <section className="hero-wrapper2 bizbanner">
                        {/* <div className="hero-overlay"></div> */}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12 col-px-12">
                                    <div className='LandingTopLogo text-center mt-lg-5 mt-3'>
                                        <img src={LandingTopLogo} alt='Logo' />
                                    </div>
                                    <div className='MainHeadingLanding w-50 m-auto text-center py-4'>
                                        <h1>Welcome to Katika Content Creators</h1>
                                        {/* <h1>Unlock Your Brand's Potential with Katika</h1> */}
                                        <p className='mt-3 mb-1'>Content Creation for Small Businesses,<br></br>Non-Profits, and Public Agencies in Philadelphia</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                    <div className='container'>
                        <div className='MainLandingImg text-center'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/HakUQ3U_Y_g?si=px_eKZB3DfFFL2xy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            {/* <img src={MainTopImg} alt='Img1' /> */}
                        </div>
                        <div className='SignUpBtnLandingPage text-center'>
                            {/* <button>Sign Up Now</button> */}
                            <button onClick={() => document.documentElement.scrollTop = document.documentElement.scrollHeight} className="btn btn-primary px-5 m-0 py-2">Sign Up Now</button>

                        </div>
                    </div>
                </div>
                <section className='WhoWeAre pb-lg-5'>
                    <div className='arrow-up'></div>
                    <div className='container'>
                        <div className='WhoWeAreContent'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h1>Who We Are</h1>
                                    <p>At Katika, we are on a mission to empower small businesses, non-profits, and public agencies by offering expert content creation and marketing services. Our value lies in bridging the gap between your brand and your local community, making your business more visible and engaging.

                                        With Katika, you can take your marketing efforts to the next level. We understand the importance of advertising your brand both online and in the community. Our comprehensive approach ensures that you reach your target audience where they are, leading to increased sales and engagement.

                                        Are you struggling to post consistently and effectively online? Katika provides a simple and convenient solution. Our monthly subscription service offers you a dedicated marketing team to boost your brand's exposure. </p>

                                    <p>With Katika, you can take your marketing efforts to the next level. We understand the importance of advertising your brand both online and in the community. Our comprehensive approach ensures that you reach your target audience where they are, leading to increased sales and engagement.</p>

                                    <p>Are you struggling to post consistently and effectively online? Katika provides a simple and convenient solution. Our monthly subscription service offers you a dedicated marketing team to boost your brand's exposure.
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={IMG6} alt='Video Place Holder' />
                                    {/* <video width="320" height="240" controls>
                                        <source src="https://media.istockphoto.com/id/1470765116/video/rear-view-business-woman-team-leader-discussing-project-with-colleagues-sharing-creative.mp4?s=mp4-640x640-is&k=20&c=jckcSo_z7FkXBJnq0GoGnLfZVHamaqOgiOjs1z0V5QI=" type="video/mp4"></source>
                                        <source src="https://media.istockphoto.com/id/1470765116/video/rear-view-business-woman-team-leader-discussing-project-with-colleagues-sharing-creative.mp4?s=mp4-640x640-is&k=20&c=jckcSo_z7FkXBJnq0GoGnLfZVHamaqOgiOjs1z0V5QI=" type="video/ogg"></source>
                                        Your browser does not support the video tag.
                                    </video> */}
                                </div>
                                <div className='col-lg-4'>
                                    <img src={IMG4} alt='Img Place Holder' />
                                </div>
                                <div className='col-lg-4'>
                                    <img src={IMG8} alt='Img Place Holder' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* {Slider} */}

                <section className="presence-content LandingHowItWorks mt-lg-5 pt-4">
                    <div class="container">
                        <div className="row">
                            <div className='col-lg-12'>
                                <div className='LandingHedingHowIt text-center'>
                                    <h1>How it works</h1>
                                </div>
                            </div>
                        </div>
                        <div className='mt-lg-5 mt-3'>
                            <div className='row'>
                                <div className="col-lg-6 col-12">
                                    <div class="content-presence BusinessInfo d-flex align-items-center">
                                        <div className='w-75'>
                                            <h1>Create Your Katika Profile</h1>
                                            <p>Sign up and personalize your Katika profile to start your journey towards a world of exclusive content tailored just for you.</p>
                                            {/* <p>Concisely describe what you do in this service and how do you help your target market solve their problem with this service</p> */}
                                            {/* <Link to={'/business-sign-up'} className="btn btn-default px-5">Get Started Today</Link> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div class="content-presence text-end">
                                        <img src={IMG7} alt='Works Placeholer' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-12 bottom">
                                <div class="content-presence">
                                    <img src={IMG1} alt='Works Placeholer' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div class="content-presence BusinessInfo text-end d-flex align-items-center justify-content-end">
                                    <div className='w-75 float-end'>
                                        <h1>Choose the Right<br></br>Subscription Plan</h1>
                                        <p>Explore our variety of subscription plans to find the perfect fit for your content needs and budget.</p>
                                        {/* <p>Concisely describe what you do in this service and how do you help your target market solve their problem with this service</p> */}
                                        {/* <Link to={'/business-sign-up'} className="btn btn-default px-5">Get Started Today</Link> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-lg-6 col-12 bottom">
                                <div class="content-presence BusinessInfo d-flex align-items-center">
                                    <div className='w-75'>
                                        <h1>Collaborate with Our<br></br>Content Creators</h1>
                                        <p>Connect and collaborate with our talented content creators to bring your unique vision to life.</p>
                                        {/* <p>Concisely describe what you do in this service and how do you help your target market solve their problem with this service</p> */}
                                        {/* <Link to={'/business-sign-up'} className="btn btn-default px-5">Get Started Today</Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 bottom">
                                <div class="content-presence text-end">
                                    <img src={IMG2} alt='Works Placeholer' />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-12 bottom">
                                <div class="content-presence">
                                    <img src={IMG3} alt='Works Placeholer' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div class="content-presence BusinessInfo text-end d-flex align-items-center justify-content-end">
                                    <div className='w-75 float-end'>
                                        <h1>Enjoy High-Quality<br></br>Content Delivered to You</h1>
                                        <p>Sit back and enjoy a seamless stream of high-quality content, curated and delivered directly to your Katika profile.</p>
                                        {/* <p>Concisely describe what you do in this service and how do you help your target market solve their problem with this service</p> */}
                                        {/* <Link to={'/business-sign-up'} className="btn btn-default px-5">Get Started Today</Link> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='SignUpEndBtn text-center my-lg-5 my-3'>
                                    {/* <button className='btn btn-primary px-5 m-0 py-2'>Get Started Today</button> */}
                                    <button to={'/'} onClick={() => document.documentElement.scrollTop = document.documentElement.scrollHeight} className="btn btn-primary px-5 m-0 py-2">Get Started Today</button>

                                    {/* <Link to={'/business-sign-up'} className="btn btn-primary px-5 m-0 py-2">Get Started Today</Link> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className='TestimonialAreaLanding py-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='TestimonialAreaLandingContent'>
                                    <div className='SubHedingLanding'>
                                        <h4>TESTIMONIALS</h4>
                                        <h1>Result In Numbers With Time</h1>
                                        <h6>Blake Gastroenterology</h6>
                                    </div>
                                    <p>Katika Content Creation Services has been a game-changer for my business.  The expert content they’ve crafted not only boosted my online presence bu t also deeply resonated with my target audience, leading to increased engagement and growth.</p>
                                    <div className='SignUpBtnsTesti mt-lg-4 my-3'>
                                        {/* <button className='btn btn-primary m-0 px-4 w-75 py-3'>Sign Up Now</button> */}
                                        <button onClick={() => document.documentElement.scrollTop = document.documentElement.scrollHeight}  className="btn btn-primary px-5 m-0 py-2">Sign Up Now</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-5 text-end'>
                                <img src={IMG5} alt='Works Placeholer' />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='PricePlanLanding'>
                    <div className='container'>
                        <div className='row'>
                            <Col lg={12} md={12} id="page-content-wrapper" className='padding-bottom-70px border-bottom'>
                                <div className="pkg-title text-center margin-bottom-50px">
                                    <h1 className='mb-4'>Subscription Plans</h1>

                                </div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                    <Nav variant="pills" className="flex-column d-none">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Billed Monthly</Nav.Link>
                                            <Nav.Link eventKey="second">Billed Annually</Nav.Link>
                                        </Nav.Item>

                                    </Nav>

                                    <Tab.Content className='package_plan'>
                                        <Tab.Pane eventKey="first">
                                            <Row className='row justify-content-between'>
                                                {monthly.map((monthly, i) => {
                                                    if (i > 0) {
                                                        return (
                                                            <Col lg={3} md={3} key={i}>
                                                                <section className={`dashboard-area account-page`}>
                                                                    <div className="y-page-link">
                                                                        <div className='border-bootom'>
                                                                            <h6>{monthly.description}</h6>
                                                                            <h5>
                                                                                {monthly.sub_title}
                                                                            </h5>
                                                                            {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                            {/* <h2>{monthly.cost > 0 ? `$${monthly.cost} /monthly` : 'Free'}</h2> */}
                                                                            <div className="current-pkg">
                                                                                <div className={`pkg-box plans-${i}`}>
                                                                                    {
                                                                                        i == 0 ?
                                                                                            <Link to={`business-sign-up?pkg=${monthly.slug}`}>Sign Up</Link>
                                                                                            :
                                                                                            <Link to={`business-sign-up?pkg=${monthly.slug}`}>Buy Now</Link>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ul className="PackageDetails">
                                                                            {
                                                                                monthly.rules && monthly.rules.map((rule, k) => {
                                                                                    return (
                                                                                        <li className='d-flex' key={k}><span className={`check ${i != 0 ? 'paid' : ''}`}><BiCheck /></span><p>{rule}</p></li>
                                                                                    );
                                                                                })
                                                                            }
                                                                            <div className="terms b-funnel">*Add on in-person meetings cost $50 per session</div>
                                                                        </ul>
                                                                        {/* {i == 0 ? <div className="terms">{this.state.Terms}</div> : ""} */}
                                                                    </div>
                                                                </section>
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Row className='justify-content-between'>
                                                {annually.map((monthly, i) => {
                                                    if (i > 0) {
                                                        return (
                                                            <Col lg={3} md={3} key={i}>
                                                                <section className={`dashboard-area account-page ${i == 0 && 'd-none'}`}>
                                                                    <div className="y-page-link">
                                                                        <div className='border-bootom'>
                                                                            <h6>{monthly.description}</h6>
                                                                            <h5>
                                                                                {monthly.sub_title}
                                                                            </h5>
                                                                            {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                            {/* <h2>{monthly.cost > 0 ? `$${monthly.cost}/annualy` : 'Free'}</h2> */}
                                                                            <div className="current-pkg">
                                                                                <div className={`pkg-box plans-${i}`}>
                                                                                    {
                                                                                        i == 0 ?
                                                                                            <Link to={`business-sign-up?pkg=${monthly.slug}`}>Sign Up</Link>
                                                                                            :
                                                                                            <Link to={`business-sign-up?pkg=${monthly.slug}`}>Buy Now</Link>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ul className="PackageDetails">

                                                                            {
                                                                                monthly.rules && monthly.rules.map((rule, k) => {
                                                                                    return (
                                                                                        <li className='d-flex mb-2' key={k}><span className={`check ${i != 0 ? 'paid' : ''}`}><BiCheck /></span><p>{rule}</p></li>
                                                                                    );
                                                                                })

                                                                            }
                                                                        </ul>
                                                                        {/* {i == 0 ? <div className="terms">{this.state.Terms}</div> : ""} */}
                                                                    </div>
                                                                </section>
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </div>
                    </div>
                </section>
                <section className='SignMeUp py-lg-5 py-3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='SignMeLanding'>
                                    <h2>Sign me up! I want this service</h2>
                                    <p>Fill out the form below to get started</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <ul className='SignMeUpPoints w-75 mx-auto mt-lg-5 mt-3'>
                                    <li className='d-flex align-items-center mb-3'>
                                        <span className='me-3'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.625 0.703125C5.20312 0.703125 0 5.95312 0 12.3281C0 18.75 5.20312 23.9531 11.625 23.9531C18 23.9531 23.25 18.75 23.25 12.3281C23.25 5.95312 18 0.703125 11.625 0.703125ZM18.1875 9.09375L17.1094 8.01562C16.9219 7.78125 16.5469 7.78125 16.3125 8.01562L9.70312 14.5781L6.89063 11.7656C6.65625 11.5312 6.32813 11.5312 6.09375 11.7656L5.01563 12.7969C4.82813 13.0312 4.82813 13.4062 5.01563 13.5938L9.28125 17.9062C9.51562 18.1406 9.84375 18.1406 10.0781 17.9062L18.1875 9.89062C18.375 9.65625 18.375 9.28125 18.1875 9.09375Z" fill="#F11200" />
                                            <path d="M18.3125 8.95703L17.2344 7.87891C17.0469 7.64453 16.6719 7.64453 16.4375 7.87891L9.82812 14.4414L7.01562 11.6289C6.78125 11.3945 6.45312 11.3945 6.21875 11.6289L5.14062 12.6602C4.95312 12.8945 4.95312 13.2695 5.14062 13.457L9.40625 17.7695C9.64062 18.0039 9.96875 18.0039 10.2031 17.7695L18.3125 9.75391C18.5 9.51953 18.5 9.14453 18.3125 8.95703Z" fill="white" />
                                        </svg>
                                        </span>
                                        <p>Get quality content tailored to your brand and audience</p>
                                    </li>
                                    <li className='d-flex align-items-center mb-3'>
                                        <span className='me-3'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.625 0.703125C5.20312 0.703125 0 5.95312 0 12.3281C0 18.75 5.20312 23.9531 11.625 23.9531C18 23.9531 23.25 18.75 23.25 12.3281C23.25 5.95312 18 0.703125 11.625 0.703125ZM18.1875 9.09375L17.1094 8.01562C16.9219 7.78125 16.5469 7.78125 16.3125 8.01562L9.70312 14.5781L6.89063 11.7656C6.65625 11.5312 6.32813 11.5312 6.09375 11.7656L5.01563 12.7969C4.82813 13.0312 4.82813 13.4062 5.01563 13.5938L9.28125 17.9062C9.51562 18.1406 9.84375 18.1406 10.0781 17.9062L18.1875 9.89062C18.375 9.65625 18.375 9.28125 18.1875 9.09375Z" fill="#F11200" />
                                            <path d="M18.3125 8.95703L17.2344 7.87891C17.0469 7.64453 16.6719 7.64453 16.4375 7.87891L9.82812 14.4414L7.01562 11.6289C6.78125 11.3945 6.45312 11.3945 6.21875 11.6289L5.14062 12.6602C4.95312 12.8945 4.95312 13.2695 5.14062 13.457L9.40625 17.7695C9.64062 18.0039 9.96875 18.0039 10.2031 17.7695L18.3125 9.75391C18.5 9.51953 18.5 9.14453 18.3125 8.95703Z" fill="white" />
                                        </svg>
                                        </span>
                                        <p>Boost your online presence and engagement to convert into sales</p>
                                    </li>
                                    <li className='d-flex align-items-center mb-3'>
                                        <span className='me-3'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.625 0.703125C5.20312 0.703125 0 5.95312 0 12.3281C0 18.75 5.20312 23.9531 11.625 23.9531C18 23.9531 23.25 18.75 23.25 12.3281C23.25 5.95312 18 0.703125 11.625 0.703125ZM18.1875 9.09375L17.1094 8.01562C16.9219 7.78125 16.5469 7.78125 16.3125 8.01562L9.70312 14.5781L6.89063 11.7656C6.65625 11.5312 6.32813 11.5312 6.09375 11.7656L5.01563 12.7969C4.82813 13.0312 4.82813 13.4062 5.01563 13.5938L9.28125 17.9062C9.51562 18.1406 9.84375 18.1406 10.0781 17.9062L18.1875 9.89062C18.375 9.65625 18.375 9.28125 18.1875 9.09375Z" fill="#F11200" />
                                            <path d="M18.3125 8.95703L17.2344 7.87891C17.0469 7.64453 16.6719 7.64453 16.4375 7.87891L9.82812 14.4414L7.01562 11.6289C6.78125 11.3945 6.45312 11.3945 6.21875 11.6289L5.14062 12.6602C4.95312 12.8945 4.95312 13.2695 5.14062 13.457L9.40625 17.7695C9.64062 18.0039 9.96875 18.0039 10.2031 17.7695L18.3125 9.75391C18.5 9.51953 18.5 9.14453 18.3125 8.95703Z" fill="white" />
                                        </svg>
                                        </span>
                                        <p>Affordable subscription plans to have consistent marketing for your business</p>
                                    </li>
                                    <li className='d-flex align-items-center mb-3'>
                                        <span className='me-3'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.625 0.703125C5.20312 0.703125 0 5.95312 0 12.3281C0 18.75 5.20312 23.9531 11.625 23.9531C18 23.9531 23.25 18.75 23.25 12.3281C23.25 5.95312 18 0.703125 11.625 0.703125ZM18.1875 9.09375L17.1094 8.01562C16.9219 7.78125 16.5469 7.78125 16.3125 8.01562L9.70312 14.5781L6.89063 11.7656C6.65625 11.5312 6.32813 11.5312 6.09375 11.7656L5.01563 12.7969C4.82813 13.0312 4.82813 13.4062 5.01563 13.5938L9.28125 17.9062C9.51562 18.1406 9.84375 18.1406 10.0781 17.9062L18.1875 9.89062C18.375 9.65625 18.375 9.28125 18.1875 9.09375Z" fill="#F11200" />
                                            <path d="M18.3125 8.95703L17.2344 7.87891C17.0469 7.64453 16.6719 7.64453 16.4375 7.87891L9.82812 14.4414L7.01562 11.6289C6.78125 11.3945 6.45312 11.3945 6.21875 11.6289L5.14062 12.6602C4.95312 12.8945 4.95312 13.2695 5.14062 13.457L9.40625 17.7695C9.64062 18.0039 9.96875 18.0039 10.2031 17.7695L18.3125 9.75391C18.5 9.51953 18.5 9.14453 18.3125 8.95703Z" fill="white" />
                                        </svg>
                                        </span>
                                        <p>Expert content creators are here to service and build your brand</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='row mt-lg-5 pt-3'>
                            <div className='col-lg-6 offset-lg-3'>
                                <AvForm onValidSubmit={handleSubmit}>
                                    {
                                        loader && (
                                            <div className='LandingForm'>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <AvInput
                                                            className="form-control"
                                                            placeholder="First name "
                                                            name="first_name"
                                                            required />
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <AvInput
                                                            className="form-control"
                                                            placeholder="Last name "
                                                            name="last_name"
                                                            required />
                                                    </div>
                                                    <div className='col-lg-12 my-lg-3'>
                                                        <AvInput
                                                            className="form-control"
                                                            placeholder="Business Name"
                                                            name="business_name"
                                                            required />
                                                    </div>
                                                    <div className='col-lg-12 mb-lg-3'>
                                                        <AvInput
                                                            className="form-control phone"
                                                            name="phone"
                                                            placeholder="(xxx) xxx-xxxx"
                                                            validate={{ pattern: { value: /^\([0-9]{3}\)[-\s\.][0-9]{3}-[0-9]{4}$/ } }}
                                                            required />

                                                    </div>
                                                    <div className='col-lg-12 mb-lg-5'>
                                                        <AvInput
                                                            className="form-control"
                                                            placeholder="E-mail"
                                                            name="email"
                                                            required />
                                                    </div>
                                                    <div className='col-lg-12'>
                                                        <button className='btn btn-primary w-100 py-3'>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </AvForm>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <div className='d-none d-sm-none'>
                <div className='MobileView'>
                    <BannerMobile />
                    <div className="text-center my-4 mb-5">
                        <div className="AppContent text-center padding-bottom-50px">
                            <p><b>Katika for business</b> connects your <b>black business</b> to shoppers that live in your community.</p>
                            <a target='_blank' href={random_item(['https://apps.apple.com/us/app/katika/id1595089103', 'https://play.google.com/store/apps/details?id=com.katikabuyer'])} className="btn btn-primary rounded mt-4">Get the App</a>
                        </div>
                        <b className="color-primary mb-3">Available on iOS, Android and Web</b>
                    </div>
                    <div className="katika-wraper"><div className="container"><h1>Katika was created for people to discover local black businesses like yours.</h1></div></div>
                    <section className="presence-content">
                        <div class="container">
                            <div className="row">
                                <div className="col-lg-6 col-12 bottom">
                                    <div class="content-presence BusinessInfo">
                                        <h1>Build a local online<br></br>presence with a Katika<br></br>for Business Page</h1>
                                        <Row className='mt-4'>
                                            <div className='col-5'>
                                                <div className='MobileBiz'><img src={BizOne} /></div>
                                            </div>
                                            <div className='col-7'>
                                                <p className='w-100 mt-0'>Set up a Katika for Business profile in your neighborhood where local customers are searching for and recommending businesses like yours. </p>
                                                {/* <Link to={'/business-sign-up'} className="btn btn-default">Create Your Free Business Page</Link> */}
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 col-12">
                                    <div class="content-presence">
                                        <img src={SlideOne} />
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div class="content-presence BusinessInfo">
                                        <h1>Get the word out locally<br></br>through Business Posts</h1>
                                        <Row className='mt-4'>

                                            <div className='col-7'>
                                                <p className='w-100 mt-0'>Share updates, crowd source feedback, and draw in new customers with a post in the most visible place on Katika, ‘The Feed’ wall.  Katika business posts can help you build and nurture relationships with local customers in your specific neighborhood who are most likely to support you.</p>
                                                {/* <Link to={'/business-sign-up'} className="btn btn-default">Create Your Free Business Page</Link> */}
                                            </div>
                                            <div className='col-5'>
                                                <div className='MobileBiz'><img src={BizTwo} /></div>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-lg-6 col-12 bottom">
                                    <div class="content-presence BusinessInfo">
                                        <h1>Engage with customers, businesses and organizations with messaging.</h1>
                                        <Row className='mt-4'>
                                            <div className='col-5'>
                                                <div className='MobileBiz'><img src={BizThree} /></div>
                                            </div>
                                            <div className='col-7'>
                                                <p className='w-100 mt-0'>Message with other businesses and organizations on the platform empowering you to strenghten your network.  Engage with customers through Katika.</p>
                                                {/* <Link to={'/business-sign-up'} className="btn btn-default">Create Your Free Business Page</Link> */}
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <Row className='mt-1 mb-5'>
                                <div className='col-lg-12'>
                                    <div className='GetStarted'>
                                        <button onClick={() => document.documentElement.scrollTop = document.documentElement.scrollHeight} className='btn btn-primary rounded-50'>GET STARTED NOW</button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </section>
                    <section className="testimonial-area padding-top-0px padding-bottom-60px text-center">
                        <div className="container-fluid">
                            <div className="row section-title-width text-center">
                                <Col lg={12}>
                                    <div className="Rectangle Video">
                                        <Row>
                                            <Col sm={6} lg={6}>
                                                <div className="VideoContent">
                                                    {/* <img src={Community} /> */}
                                                    <div className="ratio ratio-16x9">
                                                        {/* <iframe
                                                        src="https://www.youtube.com/embed/vlDzYIIOYmM"
                                                        title="YouTube video"
                                                        allowfullscreen
                                                    ></iframe> */}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={6} lg={6}>
                                                <div className="VideoBox text-center">
                                                    <h1 className="text-start">Watch free business education videos</h1>
                                                    <p className="text-start">Understanding basic to advanced business practices is key for business owners to become successful. We provide our Katika for business users with a free library of  educational videos to support all stages of entrepreneurship from setup, to branding, to operations, and more!</p>
                                                    {/* <Link to={'/business-sign-up'} className="btn btn-primary rounded">WATCH MORE VIDEOS</Link> */}
                                                    <Col lg={12}>
                                                        <div className="GetStartedBtn mt-5">
                                                            <button className="btn btn-primary rounded-50">WATCH MORE VIDEOS</button>
                                                        </div>
                                                    </Col>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </section>
                    <section className="testimonial-area padding-top-30px padding-bottom-0px text-center">
                        <div className="container">
                            <div className="row section-title-width text-center">
                                {/* <div className="BHeading">
                            <h2>Here’s what other business owners on <span>Katika</span> have to say</h2>
                        </div>
                        <Col sm={4} lg={4}>
                            <div className="OwnersSay">
                                <div className="img-area">
                                </div>
                                <div className="comment">
                                    “People love scheduling<br></br>  their appointments on Katika”
                                </div>
                                <div className="OwnerName">
                                    Jennifer Gore, CEO of Weleet
                                </div>
                            </div>
                        </Col>
                        <Col sm={4} lg={4}>
                            <div className="OwnersSay">
                                <div className="img-area">
                                </div>
                                <div className="comment">
                                    “Katika allows me to message my customers directly.”
                                </div>
                                <div className="OwnerName">
                                    Jamal Rashad, CEO of Mashful
                                </div>
                            </div>
                        </Col>
                        <Col sm={4} lg={4}>
                            <div className="OwnersSay">
                                <div className="img-area">
                                </div>
                                <div className="comment">
                                    “I love getting discovered and booked all in one place now, it makes my life easier.”
                                </div>
                                <div className="OwnerName">
                                    Christina Gore, Music DJ
                                </div>
                            </div>
                        </Col>
                         */}
                                <Col sm={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                                    <div className="FaqsQuestion padding-bottom-100px">
                                        <div className="SubHeading padding-bottom-30px text-start">Frequently Asked Questions</div>
                                        <section className="faq-area">
                                            <Accordion className="accordion accordion-item" id="accordionExample" allowZeroExpanded={true}>

                                                {items.map((item, i) => {
                                                    return (
                                                        <div className={'card ' + item.cardClass} key={i}>
                                                            <AccordionItem>
                                                                <AccordionItemHeading className="card-header">
                                                                    <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                        {item.title}
                                                                        <i className="minus">{item.minus}</i>
                                                                        <i className="plus">{item.plus}</i>
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div className="card-body text-align-left">
                                                                        <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        </div>
                                                    )
                                                })}
                                            </Accordion>
                                        </section>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {/* <Footer /> */}
            <section className='LandingFooter mt-lg-5 mt-3'>
                <div className='container'>
                    <div className='col-lg-12'>
                        <div className='LandingFooterContent'>
                            <h3>Give us a call if you have any questions</h3>
                            <h1>215-650-6539</h1>
                            <p className='mt-lg-5 mt-3 text-white'>Copyright © 2024 Katika All Rights Reserved. Made With Passion In Philadelphia, PA</p>
                        </div>
                    </div>
                </div>
            </section>

            <ScrollTopBtn />
        </main>
    )
}

export default Business;