import React, { Component, useState } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import ScheduleTabs from "../";
import ScheduleTabs from "../../components/user/ScheduleTabs";


import { ToastContainer, toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaInfo } from 'react-icons/fa';
import breadcrumbimg from '../../assets/images/bread-bg.jpg'
import placeimg from '../../assets/images/img25.jpg'

import Inputmask from "inputmask";

import { loadStripe } from '@stripe/stripe-js';

import { CardElement, Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm.js"
import axios from 'axios';
import { uToken } from '../../useToken';
import { ApiUrl, StripePublishableKey } from '../../config';


class WebsiteDreamerCheckOut extends Component {
    constructor() {
        super();
        this.state = {
            checked: false,
            externalData: false,
            breadcrumbimg: breadcrumbimg,
            img: placeimg,
            plan: {}

        };
    }

    componentDidMount() {
        this.getPlan();

    }

    getPlan = () => {
        const { id } = this.props.match.params;
        var self = this;
        self.setState({
            externalData: false,
        })
        var self = this;

        axios.post(ApiUrl + 'planByID', { 'slug': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    plan: res.data.plan,
                    externalData: true,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const stripePromise = loadStripe(StripePublishableKey);




        return (
            <main className="dashboard-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>

                        <Col lg={12} md={12} id="page-content-wrapper" className='checkout-page'>
                            <section className="dashboard-area">
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm plan={this.state.plan} />
                                </Elements>

                            </section>
                            <ToastContainer />
                        </Col>
                    </Row >
                </Container >
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default WebsiteDreamerCheckOut;

