import React, { useEffect, useState, Suspense, lazy } from 'react'
// import GeneralHeader from '../../components/common/GeneralHeader'
import NavbarHome from '../../components/common/NavbarHome'
import BannerOne from '../../components/directories/BannerOne'
// import CityList from '../../components/directories/CityList'
// import RecentActivity from '../../components/directories/RecentActivity'
import BrowseCategories from "../../components/directories/BrowseCategories";
// import MobileApp from "../../components/directories/MobileApp";
// import Stories from '../../components/directories/Stories'
// import Posts from '../../components/directories/Posts'


// const BannerOne = lazy(() => import('../../components/directories/BannerOne'));

// const renderLoader = () => <p>Loading</p>;



import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import PostsListingList from '../listings/PostListBanner'
import { useHistory } from 'react-router-dom';



const Home = (props) => {

    const [location, setLocation] = useState({});
    const history = useHistory();

    useEffect(() => {
        var loc = JSON.parse(localStorage.getItem('location'));
        let data = {};
        if (loc != null) {
            data = {
                'city': loc.city,
                'region': loc.region,
                'country': loc.country,
                'lat': loc.latitude,
                'lng': loc.longitude,

            };
        } else {
            data = {
                'city': 'Philadelphia',
                'region': 'PA',
                'country': 'USA',
                'lat': 39.9525839,
                'lng': -75.1652215,
            };
        }
        setLocation(data);
    }, [!location]);

    // const handleSuggestionSelected = ({ search, location, lat, lng, category_name, category_id }) => {
    const handleSuggestionSelected = ({ search, location, lat, lng }) => {

        // var loc = JSON.parse(localStorage.getItem('location'));
        // let data = {};
        // if (loc != null) {
        //     data = {
        //         'city': loc.city,
        //         'region': loc.region,
        //         'country': loc.country,
        //         'lat': loc.latitude,
        //         'lng': loc.longitude,

        //     };
        // } else {
        //     data = {
        //         'city': 'Philadelphia',
        //         'region': 'PA',
        //         'country': 'USA',
        //         'lat': 39.9525839,
        //         'lng': -75.1652215,
        //     };
        // }
        // setLocation(data);
        // Logic to handle the suggestion selection
        // console.log(`Selected suggestion: ${search}, Location: ${data.city}, ${data.region}, Lat: ${data.lat}, Lng: ${data.lng}`);
        // Perform any other actions here, such as updating state or making API calls

        // console.log(`Selected suggestion: ${search}, Location: ${location}, Lat: ${lat}, Lng: ${lng}, category_name: ${category_name}, category_id: ${category_id}`);
        console.log(`Selected suggestion: ${search}, Location: ${location}, Lat: ${lat}, Lng: ${lng}`);
        // const url = `/listing/${search.toLowerCase().replace(/ /g, '-')}/pa/${location.toLowerCase().replace(/ /g, '-')}`;
        const url = `/listing/${search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`;
        
        history.push(url);
        // props.handler({ search: search, location: location, lat: lat, lng: lng });
    };
    

    return (
        <main className="home-1">
            {/* Header */}

            {/* <GeneralHeader /> */}
            <NavbarHome handler={handleSuggestionSelected} />
            {/* <NavbarHome /> */}
            {/* Hero Banner */}

            {/* 
            <Suspense fallback={<div>Loading...</div>}>
                <BannerOne />
                <CityList />
                <Stories />
                <RecentActivity history={props.history} />
                <BrowseCategories />
                <MobileApp />
            </Suspense> */}

            <Suspense fallback={<div>Loading...</div>}>
                <BannerOne />

                {
                    location.city && (
                        <PostsListingList location={location} />
                    )
                }

                {/* <CityList /> */}
                {/* <Posts /> */}
                {/* <Stories /> */}
                {/* <RecentActivity history={props.history} /> */}
                {/* <BrowseCategories /> */}
                {/* <MobileApp /> */}
            </Suspense>



            {/* Footer */}
            <Footer />

            <ScrollTopBtn />
        </main>
    )
}

export default Home;